import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import Pack from '../../assets/Icons/Pack.svg'
import Tick from '../../assets/Icons/Tick.svg'
import Spin from '../../assets/Icons/Spin.svg'
import { BnSubpage } from '../Page'
import { HomeButton, Account } from '../Home'
import { KeyboardButton } from '../Keyboard'
import { isDesktop } from '../../classes/Platform.js'
import { stripePromise } from '../Client'
import Cross from '../../assets/Icons/Cross_1.svg'
/*
import {Elements} from '@stripe/react-stripe-js'
import {ElementsConsumer, CardElement, PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js'
*/
import './index.css'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { BnRemoveButton, BnButton } from '../Button'
import { delay } from '../../classes/Util.js'

class CardSelection extends Component {

  render() {
    return <div className='keyboardCreditCardSelection'></div>
  }
}



class PaymentMethodInput extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.onCreate(this)
  }

  renderCardElement() {
    let color = 'gray'
    let background = 'white'
    const CARD_OPTIONS = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#e7e7e7',
          color: '#e7e7e7',
          fontWeight: 500,
          fontSize: '16px',
          caretColor: '#e7e7e7',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#e7e7e7',
          },
          '::placeholder': {
            color: '#e7e7e7'
          },
        },
        webkitAutoFill: {
          color: '#e7e7e7',
          caretColor: '#e7e7e7',
          backgroundColor: background
        },
        invalid: {
          iconColor: '#ffc7ee',
          color: '#ffc7ee',
        },
      },
    }
    return <div className='bnCardElement'><CardElement onChange={this.onChangePaymentMethod} options={CARD_OPTIONS}/></div>
  }

  onChangePaymentMethod = e => {
    this.setState({
      paymentMethodError: null
    })
    //console.log(e)
  }


  onChangePaymentElement = e => {
    //console.log('payment element', e)
    const updates = {
      paymentComplete: e.complete,
      paymentType: e.value ? e.value.type : '',
      paymentMethodError: ''
    }
    this.setState(updates)
    this.props.onStatusChange(updates)
  }

  apply = async () => {
    if (!this.state.paymentComplete) {
      const error = {
        paymentMethodError: 'Payment method incomplete'
      }
      this.setState(error)
      return {
        error: error.paymentMethodError
      }
    }
    if (this.props.setupIntent) {
      const elements = this.elements
      const result = await this.stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: "https://intellikey.app",
        },
        redirect: 'if_required'
      })
      return result
    } else if (this.props.paymentIntent) {
      const elements = this.elements
      const result = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "http://intellikey.app",
        },
        redirect: 'if_required'
      })
      debugger
      return result
    }
    return {}
  }


  renderPaymentMethodInput(clientSecret) {
    if (clientSecret) {
      const options = {
        clientSecret,
        appearance: {
          variables: {
            colorPrimary: 'rgb(59, 101, 201)',
            colorBackground: 'rgb(59, 101, 201)',
            colorText: 'rgb(139, 214, 134)',
            fontFamily: 'apparat, sans-serif',
            fontWeightNormal: '550',
            borderRadius: '0px',
          },
          rules: {
            '.Label': {
              color: 'rgb(139, 214, 134)',
              'font-family': 'apparat, sans-serif'
            },
            '.Input': {
              color: '#333333',
              backgroundColor: '#e7e7e7',
              colorTextPlaceHolder: '#888888',
              'font-family': 'apparat, sans-serif'
            }
          }
        }
      }
      /*
      return <Elements stripe={stripePromise()} options={options}>
               <ElementsConsumer>
                 {({elements, stripe}) => {
                   this.stripe = stripe
                   this.elements = elements;
                   return <PaymentElement onChange={this.onChangePaymentElement}/>
                 }
                 }
               </ElementsConsumer>
               </Elements>
               */
    }
    return null
  }

  render() {
    let clientSecret
    if (this.props.setupIntent) {
      clientSecret = this.props.setupIntent.clientSecret
    } else if (this.props.paymentIntent) {
      clientSecret = this.props.paymentIntent.clientSecret
    }
    console.log("clientSecret", clientSecret)
    return <div className='bnPaymentMethodInput'>
      {this.renderPaymentMethodInput(clientSecret)}
      </div>
  }
}

const SummaryLine = props => {
  let className = 'keyboardWordPackSummaryLine'
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className}>
           <div className='keyboardWordPackSummaryLineLabel'>
             {props.label}
           </div>
           <div className='keyboardWordPackSummaryLineValue'>
             {props.value}
           </div>
         </div>
}
 
const TotalLine = props => {
  const { value } = props
  return SummaryLine( {
    label: 'Total',
    value,
    className: 'keyboardWordPackTotalLine'
  })
}


export class WordPackPurchase extends BnSubpage {
  constructor (props) {
    super(props)
    this.state.wordPacks = []
    this.state.status = 'Add words to your account'
    this.state.isPurchase = false
    this.state.paymentMethods = []
  }

  wordPacks = {}
  
  componentDidMount() {
    if (!this.props.wordPack) {
      this.sub = this.props.me.observeWordPacks().subscribe(change => {
        debugger
        if (this.state.busy) {
          const resolve = this.state.busy
          this.state.busy = null
          this.forceUpdate()
          resolve()
        }
        const {type, wordPack } = change
        if (type == 'removed') {
          delete this.wordPacks[workdPack.id]
        } else {
          this.wordPacks[wordPack.id] = wordPack
        }
        this.updateWordPacksLater()
      })
    } else {
      this.setState({
        status: 'payment summary',
        wordPacks: [this.props.wordPack],
        isPurchase: true
      })
      if (false && !this.props.me.isNative()) {
        debugger
        this.paymentMethodSub = this.props.me.observePaymentMethods().subscribe(({selectedPaymentMethod, paymentMethods}) => {
          debugger
          this.setState({
            selectedPaymentMethod,
            paymentMethods
          })
        })
        this.props.me.getPaymentMethods().then(result => {
          if (!result.selectedPaymentMethod) {
            this.props.me.createCustomerSetupIntent().then(result => {
              if (result.error) {
              } else {
                const { setupIntent } = result
                this.setState({
                  setupIntent
                })
              }
            })
          } 
          this.setState(result)
        })
      }
      this.props.me.createCustomerPaymentIntent(this.props.wordPack.productId).then(paymentIntent => {
        debugger
        this.setState({
          paymentIntent
        })
      })
    }
  }
  
  componentWillUnmount() {
    if (this.sub) this.sub.unsubscribe()
    if (this.paymentSub) {
      this.paymentSub.unsubscribe()
    }
  }

  updateWordPacksLater = () => {
    clearTimeout(this.wordPackUpdater)
    this.wordPackUpdater = setTimeout(this.updateWordPacksNow, 200)
  }

  updateWordPacksNow = () => {
    const wordPacks = Object.values(this.wordPacks)
    wordPacks.sort((x, y) => {
      return y.price - x.price
    })
    this.setState({
      wordPacks 
    })
  }

  purchase = async (wordPack, status) => {
    if (this.state.isPurchase) {
      return await this.buy(wordPack, status)
    } else {
      this.setState({
        subpage: () => <WordPackPurchase
                         title='Word Packs' wordPack={wordPack} me={this.props.me} back={this.back} available={this.props.available}/>
      })
    }
  }

  buyWithStripe = async (wordPack, status) => {
    this.setState({
      status: "payment complete",
      paymentStatus: 'payment complete',
      paymentStatusMsg: 'added',
    })
    await this.props.me.waitForStripePurchase(this.state.paymentIntent)
    this.props.back()
  }

  buy = async (wordPack, status) => {
    if (!this.props.me.isNative()) {
      return this.buyWithStripe(wordPack, status)
    }
    this.state.status = "processing payment"
    this.state.purchaseStatus = 'purchase'
    this.forceUpdate()
    const result = await this.props.me.purchaseWordPack(wordPack, paymentStatus => {
      let msg
      let status = this.state.status
      switch (paymentStatus) {
        case 'purchase':
          msg = "Contacting Apple"
          break
        case 'verify':
          msg = 'Verifying Apple Receipt'
          break
        case 'complete':
          msg = 'added'
          status = 'payment complete'
          break
        case 'canceled':
          msg = 'Payment canceled'
          status = 'payment canceled'
          break
        case 'failed':
          msg = "Oof, Sorry that didn't work"
          break
      }
      this.setState({
        status,
        paymentStatus,
        paymentStatusMsg: msg
      })
    })
    if (result) {
      console.log('purchase complete', wordPack)
      this.props.back()
    } else {
      console.log('purchase canceled')
    }
  }

  activateReferralCode = () => {
    this.setState({
      subpage: () => <Account title={"Account"} me={this.props.me}
                              getSetting={this.props.getSetting}
                              toggleSetting={this.props.toggleSetting}
                              back={this.back}/>
    })
  }

  
  renderContent() {
    return <div className='bnSubpageContent'>
             { (this.props.me.isAdmin || this.props.me.isNative() || !this.props.me.isNative()) &&
               <div className='keyboardWordPackContent'>
             <div className='keyboardWordPackHeader'>
               <div className='keyboardWordPackHeaderLabel'>
                 {this.state.status}
               </div>
               <div className='keyboardWordPackHeaderAvailable'>
                 <div className='keyboardWordPackHeaderAvailableAmount'>
                   {this.props.available}
                 </div>
                 <div className='keyboardWordPackHeaderAvailableLabel'>
                   available
                 </div>
               </div>
             </div>
             <div className='keyboardWordPurchase'>
               {
                 this.state.wordPacks.map(wordPack => {
                   return <WordPackButton
                            key={wordPack.id} wordPack={wordPack}
                            me={this.props.me}
                            back={this.props.back}
                            isPayment={this.state.isPurchase}
                            setupIntent={this.state.setupIntent}
                            paymentIntent={this.state.paymentIntent}
                            paymentMethods={this.state.paymentMethods}
                            selectedPaymentMethod={this.state.selectedPaymentMethod}
                            paymentStatus={this.state.paymentStatus}
                            paymentStatusMsg={this.state.paymentStatusMsg}
                            purchase={()=>this.purchase(wordPack)}/>
                 })
               }
            </div>
               </div>}
             {!this.state.isPurchase &&
             <div key='refer' className='keyboardWordPackRefer'>
               <HomeButton icon={Pack} label='Refer your friends' buttonLabel=<span>Get <span className='FREE'>FREE</span> word packs</span> action={this.activateReferralCode}/>
               />
               </div>}
             </div>
  }
}

class WordPackButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }

  onPaymentMethodStatusChange = status => {
    this.setState(status)
  }

  setPaymentMethodInput = ref => {
    this.paymentMethodInput = ref
  }

  buy = async () => {
    if (this.paymentMethodInput) {
      this.setState({
        paymentMethodError: null
      })
      const result = await this.paymentMethodInput.apply()
      if (result.error) {
        this.setState({
          paymentMethodError: result.error
        })
        return
      }
    }
    return await this.props.purchase()
  }
  
  render() {
    let msg
    let statusClass = 'keyboardWordPackStatus'
    const formatAmount = wordCount => {
      if (wordCount < 1000000) {
        return Math.round(wordCount / 1000)+'K'
      }
      else {
        let n = wordCount / 1000000
        if (n % 1 !== 0) {
          n = n.toFixed(1)
        }
        return n + 'M'
      }
    }
    const formatWordCount = wordCount =>  <span><span className='wordCount'>{formatAmount(wordCount)}</span>&nbsp;words</span>
    const formatPrice = price => '$'+price.toFixed(2) + ' USD'
    const busy = this.state.busy
    const wordPack = this.props.wordPack
    const { name, wordCount, ourPrice, price } = wordPack
    let apple = formatPrice(price) 
    let subtotal = formatPrice(ourPrice)
    let total
    if (!this.props.me.isNative()) {
      total = subtotal
    } else {
      total = formatPrice(price)
    }
    let className = 'keyboardWordPack' + (this.props.isPayment ? ' keyboardWordPackPayment' : '')
    let statusRight = <div className='keyboardWordPackWordPrice'>{total}</div>
    const renderStatus = msg => {
      return <div className='keyboardWordPackPaymentStatus'>{msg}</div>
    }
    let buyButton
    let spin = false
    if (this.props.isPayment) {
      buyButton = <KeyboardButton label='Buy' icon={Tick} action={this.buy}/>          
      switch (this.props.paymentStatus) {
        case 'canceled':
          className += ' keyboardWordPackPaymentStatusCanceled'
          buyButton = null
          statusRight = renderStatus(this.props.paymentStatusMsg)
          break
        case 'complete':
          className += ' keyboardWordPackPaymentStatusComplete'
          statusRight = renderStatus(this.props.paymentStatusMsg)
          buyButton = <KeyboardButton label='Done' icon={Tick} action={this.props.back}/>          
          break
        case 'purchase':
        case 'verify':
          spin = true
          statusRight = renderStatus(this.props.paymentStatusMsg)
          className += ' keyboardWordPackPaymentStatusProcessing'
          buyButton = null
          break
      }
      if (!this.props.me.isNative()) {
        if (!this.props.selectedPaymentMethod &&
            !this.props.setupIntent &&
            !this.props.paymentIntent) {
          spin = true
          buyButton = null
        }
      }
    }
    const setPaymentMethod = async paymentMethod => {
      this.setState({
        selectedPaymentMethod: paymentMethod
      })
    }

    let feeLine
    if (!!this.props.me.isNative()) {
      feeLine = <SummaryLine label='Apple Fee' value={formatPrice(price - ourPrice)}/>
    } else {

    }
    let paymentMethod = this.props.selectedPaymentMethod
    console.log("paymentIntent", this.state.paymentIntent)
    return <div className={className}>
             <div className='keyboardWordPackRow1' onClick={buyButton ? undefined: this.props.purchase}>
             <div className='keyboardWordPackLeft'>
               <div className='keyboardWordPackBuyButtonIcon'>
                 <ReactSVG src={Pack}/>
               </div>
               <div className='keyboardWordPackWordCount'>{formatWordCount(wordCount)}</div>
             </div>
             <div className='keyboardWordPackRight'>
               {statusRight}
             </div>
             </div>
             {this.props.isPayment && <div key='row2' className='keyboardWordPackRow2'>
                                        {!this.props.me.isNative() && <PaymentMethodInput
                                                                        onCreate={this.setPaymentMethodInput}
                                                                        onStatusChange={this.onPaymentMethodStatusChange}
                                                                        setupIntent={this.props.setupIntent}
                                                                        selectedPaymentMethod={this.props.selectedPaymentMethod}
                                                                        paymentMethods={this.props.paymentMethods}
                                                                        paymentIntent={this.props.paymentIntent}
                                                                        setPaymentMethod={setPaymentMethod}
                                                                        me={this.props.me}/>}
                                        <div className='keyboardWordPackOrderSummary'>
                                          <SummaryLine label='Price' value={subtotal}/>
                                          {feeLine}
                                          <TotalLine l value={total}/>
                                        </div>
                                        {buyButton}
                                        {this.state.paymentMethodError &&
                                         <div className='wordpackPaymentMethodError'>
                                           <HomeButton icon={Cross} buttonLabel={this.state.paymentMethodError}/>
                                         </div>
                                        }
                                           
                                      </div>}
             {spin && <div key='spin' className='wordPackSpinContainer'>
                        <ReactSVG src={Spin}/>
                      </div>}
           </div>
  }

}


