import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isDesktop } from '../../classes/Platform.js'
import { Home } from '../Home'
import { Keyboard } from '../Keyboard'
import { GetStarted, KeyboardLogin } from '../KeyboardLogin'
import { ChatGPT } from '../ChatGPT'
import { KeyboardSettings } from '../KeyboardSettings'
import Logo from '../../assets/Icons/KB3DLogo.png'
import AttunewiseLogo from '../../assets/Icons/AT3DLogo.png'
import Login from '../../assets/Icons/Signin.svg'
import phone from 'phone';
import './index.css'
const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds*1000))
const Splash = props => {
  return <div key='splash' className='keyboardSplash' style={props.visible ? null: {display: 'none'}}>
           <div className='keyboardSplashContent'>
           <div className='keyboardSplashLogo'>
             <img src={props.logo || Logo}/>
           </div>
           <div className='keyboardSplashText'>
             {props.title || 'IntelliKey'}
           </div>
         </div>
         </div>
}

const ShowKeyboardLogin = props => {
  return <div key='keyboardLogin' className='keyboardLoginInProgressPage' style={props.visible ? null: {display: 'none'}}>
           <GetStarted text='Logging In' icon={Login}/>
           </div>
}

export class BnApp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      showKeyboardSettings: false,
      hasFullAccess: false,
      isInstalled: false,
      keyboardLoginInProgress: false,
      showSplash: false,
      initializing: !isDesktop(),
      settings: {
        autoCorrection: true,
        hapticFeedback: false,
        audioFeedback: false
      }
    }
    const testSettings = false
    if (testSettings) {
      this.state.showKeyboardSettings = true
      this.state.hasFullAccess = false
      this.state.isInstalled = true
    }
  }

  bg = () => {
    clearTimeout(this.splashTimer)
    this.setState({
      showSplash: true
    })
  }

  fg = () => {
    this.setState({
      showSplash: false
    })
  }

  transition = () => {
    clearTimeout(this.splashTimer)
    this.splashTimer = setTimeout(this.fg, 1000)
  }

  componentDidMount() {
    if (!isDesktop()) {
      //window.addEventListener("focus", this.transition)
      //window.addEventListener("blur", this.bg)
      window.addEventListener("resize", () => {
        this.forceUpdate()
      })
      setTimeout(() => {
        this.setState({
          initializing: false
        })
      }, 1400)
    }
    if (isDesktop) {
      const preventNavigation = (event) => {
        event.preventDefault();
      }
      window.addEventListener('dragover', preventNavigation);
      window.addEventListener('drop', preventNavigation);
    }
    this.sub0 = this.props.me.observePageRequest().subscribe(page => {
      //debugger
      this.home.selectPage(page)
    })
    this.sub1 = this.props.me.observeSelf().subscribe(self => {
      if (self) {
        this.setState({
          isLoggedIn: true
        })
        this.wordSub = this.props.me.observeAvailableWords().subscribe(available => {
          this.setState({
            notEnoughTokens: available <= 0
          })
        })
      } else {
        this.setState({
          isLoggedIn: false,
          keyboardLoginInProgress: false
        })
        if (this.wordSub) {
          this.wordSub.unsubscribe()
        }
      }
    })
    let seq = 0
    this.sub2 = this.props.me.observeKeyboardIsActive().subscribe(keyboardActive => {
      const n = ++seq
      this.transition()
      if (!keyboardActive) {
        setTimeout(() => {
          if (n === seq) {
            this.setState({
              keyboardActive
            })
          }
        }, 250)
      } else {
        this.setState({
          keyboardActive
        })
      }
    })
    this.sub3 = this.props.me.observeKeyboardStatus().subscribe(keyboardStatus => {
      console.log(keyboardStatus)
      if (!keyboardStatus.isInstalled || !keyboardStatus.hasFullAccess) {
        this.state.showKeyboardSettings = true
      }
      this.setState(
        keyboardStatus,
      )
    })
    this.sub4 = this.props.me.observeFullAccessRequested().subscribe(fullAccessRequested => {
      this.setState({
        fullAccessRequested
      })
    })
    this.sub5 = this.props.me.observeKeyboardLogin().subscribe(isInProgress => {
      this.setState({
        keyboardLoginInProgress: isInProgress && this.props.me.self
      })
    })
    this.sub6 = this.props.me.observeSettings().subscribe(settings => {
      //debugger
      this.setState({
        settings
      })
    })
  }

  componentWillUnmount() {
    if (this.wordSub) {
      this.wordSub.unsubscribe()
    }
  }

  sendMessage = text => {
    this.props.me.sendKeyboardOutput(text)
  }

  onNotEnoughTokens = () => {
    this.setState({
      notEnoughTokens: true
    })
  }

  setHome = home => {
    this.home = home
  }

  getSetting = (key) => {
    return this.state.settings[key]
  }

  toggleSetting = (key) => {
    this.state.settings[key] = !this.state.settings[key]
    this.props.me.saveSettings(this.state.settings)
    this.forceUpdate()
  }
  
  renderContent() {
    if (this.props.me.self) {
      if (this.state.showKeyboardSettings) {
        const doneClicked = () => {
          this.setState({
            showKeyboardSettings: false
          })
        }
        return <KeyboardSettings isInstalled={this.state.isInstalled}
                                 done={this.state.isInstalled && this.state.hasFullAccess}
                                 isEnabled={this.state.isInstalled}
                                 hasFullAccess={this.state.hasFullAccess}
                                 me={this.props.me} doneClicked={doneClicked}
                                 fullAccessRequested={this.state.fullAccessRequested}/>
      }
      const keyboardActive = this.state.keyboardActive && !this.state.notEnoughTokens
      return <div className='keyboardApp'>
               {
                 keyboardActive &&
                   <div key='keyboard' className='keyboardAppContent' >
                     <Keyboard onNotEnoughTokens={this.onNotEnoughTokens} key='keyboard' visible={true} openProfile={this.openProfile} sendMessage={this.sendMessage} me={this.props.me}
                               cancelKeyboardOutput={this.props.me.cancelKeyboardOutput}
                               sendKeyboardOutput={this.props.me.sendKeyboardOutput}
                     />
                   </div>
               }
               <div className='keyboardAppContent' style={!keyboardActive ? null : { display: 'none' }}>
                 <Home onCreate={this.setHome}
                       getSetting={this.getSetting}
                       toggleSetting={this.toggleSetting}
                       notEnoughTokens={this.state.notEnoughTokens} me={this.props.me}/>
                 </div>
               <Splash visible={this.state.showSplash}/>
               <ShowKeyboardLogin visible={this.state.keyboardLoginInProgress}/>
               </div>
    }
  }

  render() {
    return <div className='bnAppContent'>
             {this.renderApp()}
             </div>
  }

  onLogin = (isSignUp) => {
    this.setState({
      loginInProgress: false
    })
  }

  loginInProgress = () => {
    this.setState({
      loginInProgress: true
    })
  }
    

  renderApp() {
    if (!this.props.me || !this.props.me.self || (this.state.loginInProgress && !this.props.me.isAskAppOnly())) {
      if (this.props.me.isAskAppOnly()) {
        return <Splash visible={true} logo={AttunewiseLogo} title='Attunewise.ai'/>
      }
      if (this.state.initializing) {
        return <Splash visible={true}/>
      }
      return  <KeyboardLogin onCreate={this.loginInProgress} me={this.props.me} safeArea={true} next={this.onLogin}/>
    }
    return this.renderContent()
  }
}
