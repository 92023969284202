import { Subject, merge } from 'rxjs'
import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
//import {loadStripe} from '@stripe/stripe-js';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import { BnSplash } from '../Splash'
import { BnApp } from '../App'
import { Me } from '../../classes/Me'
import { isIPad, isAndroid, isWindows, isSafari, isDesktop, isMobile, isIOS } from '../../classes/Platform.js'
import './index.css'

let portalRef

export const getPortal = () => {
  if (window.getPortalRef) return window.getPortalRef()
  if (portalRef) return portalRef
  return null
}

const firebaseConfig = {
  apiKey: "AIzaSyCP2wfQcmg0INLx6uRZFf3T4PF-xnZHZ34",
  authDomain: "intelligentkeyboard.firebaseapp.com",
  projectId: "intelligentkeyboard",
  storageBucket: "intelligentkeyboard.appspot.com",
  messagingSenderId: "600777917766",
  appId: "1:600777917766:web:0be073906f1e14afe71147"
};

const stripeKeys = {
  dev: 'pk_test_dkomIQGNwr7Ziw4yvd6RJNxi006vne2jpJ',
  prod: 'pk_live_KVU01o7JnDfnVRQrH75nIM0O00VdBoVzEu'
}

const getEnv = () => 'prod'


const getFirebaseConfig = () => {
  return firebaseConfig
}

const config = getFirebaseConfig()

console.log('firebaseConfig', config)
firebase.initializeApp(config)

const me = new Me(firebase, config)

let _stripePromise

export const stripePromise = () => {
  return Promise.resolve()
  _stripePromise ? _stripePromise : _stripePromise = loadStripe(stripeKeys[getEnv()])
}

export class BnClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    me.observeSelf().subscribe(self => {
      this.setState({self})
      if (false && !self) {
        me.nativeLogin()
      }
    })
  }

  setPortalRef = ref => {
    if (ref) {
      portalRef = ref
      this.forceUpdate()
    }
  }

  getPlatform = () => {
    let bxPlatName = 'bnClient'

    if (isMobile()) {
      bxPlatName += ' benxtAppMobile'
    }

    if (isIOS()) {
      bxPlatName += ' benxtAppIOS'
    }

    if (isAndroid()) {
      bxPlatName += ' benxtAppAndroid'
    }

    if(isDesktop() || isIPad()) {
      bxPlatName += ' benxtAppDesktop'
    }

    if (isSafari()) {
      bxPlatName += ' benxtAppSafari'
    }
    if (isWindows()) {
      bxPlatName += ' benxtAppWindows'
    }

    if (me.isNative()) {
      bxPlatName += ' benxtAppNative'
    }

    if (me.isAskAppOnly()) {
      bxPlatName += ' benxtAskAppOnly'
    }

    if (me.isHelpful()) {
      bxPlatName += ' benxtAppAssistant'
    }
    return(bxPlatName);
  }

  render () {
    const self = this.state.self
    return <div className={this.getPlatform()} ref={this.setPortalRef}>
        <BnApp self={self} me={me}/>
    </div>
  }
}
