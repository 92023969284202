import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindCallback, of, concat, from, Subject, merge as mergeN } from 'rxjs'
import { ReactSVG } from 'react-svg'
import { walkDOM, Keyboard, parseCode, renderCode, KeyboardAutocomplete, Document, InputControl, KeyboardButton, KeyboardButton1, KeyboardTitle } from '../Keyboard'
import { BnPage, BnSubpage } from '../Page'
import { UIButton } from '../chat/components/Button'
import { UIOKCancel } from '../chat/components/OKCancel'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { delay } from '../../classes/Util.js'
import AICheck from '../../assets/Icons/AICheck.svg'
import Spin from '../../assets/Icons/Spin.svg'
import Cross from '../../assets/Icons/Cross_1.svg'
import Trash from '../../assets/Icons/Trash.svg'
import Stop from '../../assets/Icons/Stop.svg'
import MenuUp from '../../assets/Icons/MenuUp.svg'
import MenuDown from '../../assets/Icons/MenuDown.svg'
import Category from '../../assets/Icons/UserSaid.svg'
import Undo from '../../assets/Icons/Redo.svg'
import Redo from '../../assets/Icons/Undo.svg'
import Mic from '../../assets/Icons/Mic_1.svg'
import Send from '../../assets/Icons/Send.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import OpenAI from '../../assets/Icons/OpenAI.svg'
import Mistral from '../../assets/Icons/MistralLogo.svg'
import Meta from '../../assets/Icons/Meta.svg'
import Speaker from '../../assets/Icons/Speaker.svg'
import CheckMark from '../../assets/Icons/Tick.svg'
import EditIcon from '../../assets/Icons/UserSaid.svg'
import Plus from '../../assets/basketball/Plus.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Alert from '../../assets/Icons/Alert.svg'
import Hashtag from '../../assets/Icons/Hashtag.svg'
import Marker from '../../assets/Icons/Marker.svg'
import Question from '../../assets/Icons/Question.svg'
import moment from 'moment'
import { langs } from '../../classes/Lang.js'
import {SpectrumAnalyzer} from '../SpectrumAnalyzer'
import ClickAwayListener from 'react-click-away-listener'
import Markdown0 from 'markdown-to-jsx'
import Markdown1 from "react-markdown"
import { Markdown } from './Markdown.js'
import { getPortal } from '../Client'
import { useSwipeable } from 'react-swipeable'
import { parseTable, renderTable, renderTableMarkdown } from '../Keyboard/Table.js'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialLight as CodeStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { markdownToTxt } from './MarkdownToTxt.js'
import { jsonrepair } from 'jsonrepair'
import TurndownService from 'turndown'
import './index.css'
import './text.css'
const turndownService = new TurndownService()

const replaceImgWithMarkdown = (htmlString) => {
  if (htmlString.indexOf('<img') >= 0) {
    //debugger
    const regex = /<img .*\/?>/g
    return htmlString.replace(regex, (match) => {
      console.log("match", match)
      return turndownService.turndown(match)
    });
  }
  return htmlString
}


function fromNow(date) {
  const fromNow = moment(date).fromNow().replace(/ ago/, '')
  const fixed = fromNow
        .replace(/.*a few seconds.*/, 'now')
        .replace(/.*a minute.*/, '1m')
        .replace(/.*an hour.*/, '1h')
        .replace(/.*a day.*/, '1d')
        .replace(/.*a month.*/, '1mo')
        .replace(/.*a year.*/, '1y')
        .replace(/ minutes?/, 'm')
        .replace(/ hours?/, 'h')
        .replace(/ days?/, 'd')
        .replace(/ months?/, 'mo')
        .replace(/ years?/, 'y')
  //console.log('fromNow', fromNow, '=>', fixed)
  return fixed
}

//

const JSON_parse = input => {
  try {
    return JSON.parse(jsonrepair(input))
  } catch (err) {
    return eval(input)
  }
}

const nbsp = new RegExp(String.fromCharCode(160), "gi");

const USE_GPT4 = new URLSearchParams(window.location.search).get('gpt-4')


class RadioButtons extends Component {
  render() {
    const isSmall = this.props.small
    const buttons = this.props.buttons.map(button => {
      const { selected } = button
      if (selected) {
        return <div className='keyboardRadioButtonSelected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOn'>{button.label}</div>
               </div>
        
      } else {
        return <div className='keyboardRadioButtonUnselected' onClick={button.select}>
                 <div className='keyboardRadioButtonIcon'><ReactSVG src={button.icon}/></div>
                 <div className='keyboardRadioButtonOff'>{button.label}</div>
               </div>
      }
    });
    return <div className={'keyboardRadioButton' +  (isSmall ? ' keyboardRadioButtonSmall' : '')}>
             {this.props.label &&<div className='keyboardRadioButtonLabel keyboardRadioButton'>
               {this.props.label}
                                 </div>}
             {buttons}
             <div className='keyboardRadioButtonRight'/>
           </div>

  }
}


class CheckboxPopup extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = () => {
      this.state.menuActive = false
      his.forceUpdate()
    }
    return <ClickAwayListener onClickAway={closeMenu}>    
             <RadioButtons buttons={this.props.buttons}/>
           </ClickAwayListener>
  }

  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    return <div className='chatGptFunctions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
           </div>
  }
  
}


const encodeURIExt = uri => {
  if (!uri.endsWith("%3f") && !uri.endsWith("%3F")) {
    uri = encodeURI(uri)
  }
  return uri
}

const QUESTIONS = ['Does Australia really have pink lakes?',
                   'When and on what topic  was the NYT\'s first report on "artificial intelligence"?',
                   'Can spiders fly?']
const links = [`Does Australia really have [pink lakes?](ai://?q=${encodeURIComponent(QUESTIONS[0])})`,
               `When and on what topic was the NYT's [first report on "artificial intellgence"](ai://?q=${encodeURIComponent(QUESTIONS[1])})?`,
               `Can [spiders fly?](ai://?q=${encodeURIComponent(QUESTIONS[2])})`]

const generateBlurb = (pre) => {
  pre = pre || `Hello, ask me any questions you'd like. `
  return `${pre}Here are some examples to get you started:\n
${links.map((q, i) => `\n- ${q}`)}\n`.replace(/,\n/g, '\n')
}

const CodeBlock = ({className, children}) => {
  let lang 
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
    return (
      <SyntaxHighlighter language={lang} style={CodeStyle} showLineNumbers={true}>
        {children}
      </SyntaxHighlighter>
    )
  } else {
    if (children.indexOf('%') >= 0) {
      try {
        children = decodeURIComponent(children)
      } catch (err) {
        console.error(err)
      }
    }
    return <div className='aiCode'>{children}</div>
  }
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <div className='aiPre' {...rest}>{children}</div>;
};

const consoleLog = (...args) => {
}

const debugLog = (...args) => {
  //args.unshift("debug")
  //console.log.apply(null, args)
}


class CheckMarkComp extends Component {
  render( ){
    return <div className='aiCheck'><ReactSVG src={AICheck}/></div>
  }
}

const decodeURIComponentExt = c => {
  try{
    let decoded = decodeURIComponent(c)
    return decoded.replace(/[+]/g, ' ')
  } catch (exc) {
    //////debugger
    return c
  }
}

const Swiper = props => {
  const { onSwipeLeft, onSwipeRight } = props
  const config = {
    trackMouse: true,
    preventScrollOnSwipe: true,
  }
  const handlers = useSwipeable({
    onSwipedLeft: onSwipeLeft,
    onSwipedRight: onSwipeRight,
    ...config
  })
  return <div className={'swiper'} {...handlers}>
{props.children}
</div>
}

class Messages extends Component {
  constructor (props) {
    super(props)
  }
  componentWillUnmount() {
    document.removeEventListener("selectionchange", this.onSelectionChange)
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }
  componentDidMount() {
    const ref = this.scrollWindow
    this.props.onCreate(this)
    this.resizeObserver = new ResizeObserver(entries => {
      debugLog("resize", entries)
      let skip = false
      if (this.props.slide !== 0 && this.props.slide != 1) {
        skip = true
      }
      if (this.inScroll) skip = true
      if (ref.scrollHeight == 0) skip = true
      if (skip) {
        debugLog("resize skip")
      }
      this.inResize = true
      if (ref.scrollTop !== this.lastScrollTop) {
        debugLog("onresize: scrollTop", this.scrollWindow.scrollTop, "last", this.lastScrollTop)
      }
      if (ref.scrollHeight !== this.lastHeight) {
        debugLog("onresize: scrollHeight", this.scrollWindow.scrollHeight, "last", this.lastHeight)
        this.lastHeight = ref.scrollHeight
      }
      if (ref.clientHeight !== this.lastOffsetHeight) {
        debugLog("onresize:scroll clientHeight", ref.clientHeight, "last", this.lastOffsetHeight)
        this.lastOffsetHeight = ref.clientHeight
      }
      const newScrollTop = this.computeScrollTop()
      debugLog("new scroll top: ", newScrollTop)
      const newScrollBottom = ref.scrollHeight - ref.clientHeight - newScrollTop
      debugLog("current scroll bottom: ", this.scrollBottom, "=>", newScrollBottom)
      ref.scrollTop = newScrollTop
      debugLog("resize scrollTop==>", newScrollTop)
      this.inResize = false
    })
    this.scrollBottom = 0
    this.lastHeight = ref.scrollHeight
    this.lastOffsetHeight = ref.clientHeight
    ref.scrollTop = this.computeScrollTop()
    this.lastScrollTop = ref.scrollTop
    debugLog("chat mounted scroll last: ", this.lastHeight)
    const onscroll = e => {
      if (this.props.slide !== 0 && this.props.slide !== 1) {
        return
      }
      let sizeDelta = 0
      if (this.inResize) {
        return
      }
      if (this.lastOffsetHeight !== ref.clientHeight) {
        debugLog("scroll client height change: ", ref.clientHeight, "last", this.lastOffsetHeight)
        sizeDelta += ref.clientHeight - this.lastOffsetHeight
        this.lastOffsetHeight = ref.clientHeight
      }
      if (this.lastHeight !== ref.scrollHeight) {
        debugLog("scroll height change: ", ref.scrollHeight, "last", this.lastHeight)
        sizeDelta += ref.scrollHeight - this.lastHeight
        this.lastHeight = ref.scrollHeight
      }
      if (sizeDelta === 0) {
        this.scrollBottom = this.computeScrollBottom()
        this.lastScrollTop = ref.scrollTop
      } else {
        const newValue = this.computeScrollTop()
        debugLog("onscroll scrollTop==>", newValue)
        return ref.scrollTop = newValue
      }
      debugLog("scrolltop=>", ref.scrollTop)
      debugLog("scrollHeight=>", ref.scrollHeight)
      debugLog("scrollClient=>", ref.clientHeight)
      debugLog("scrollBottom=>", this.scrollBottom)
      this.checkScrollBack()
    }
    ref.onscroll = e => {
      this.inScroll = true
      onscroll(e)
      this.inScroll = false
    }
    this.resizeObserver.observe(this.scrollWindow)
    this.resizeObserver.observe(this.scrollContent)
    this.windowListener = window.addEventListener("resize", this.onResized)
    this.scrollToBottom()
  }

  pushScrollBottom() {
    this.savedScrollBottom = this.scrollBottom
    //debugLog("pushScrollBottom: ", this.savedScrollBottom)
  }

  fixupScrollTopLater = () => {
    clearTimeout(this.fixupTimeout)
    setTimeout(this.fixupScrollTop)
  }

  setScrollWindow = ref => {
    if (ref && ref != this.scrollWindow) {
      this.scrollWindow = ref
    }
  }

  checkScrollBack = async () => {
    const ref = this.scrollWindow
    if (ref.scrollTop === 0 || ref.scrollHeight <= ref.offsetHeight) {
      ////debugger
      if (this.props.checkScrollBack) {
        this.props.checkScrollBack()
      }
    }
  }
  
  setScrollContent = ref => {
    if (ref && ref != this.scrollContent) {
      this.scrollContent = ref
    }
  }

  computeScrollTop = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - this.scrollBottom - ref.clientHeight
    debugLog(`computeScrollTop ${ref.scrollHeight} - ${this.scrollBottom} - ${ref.clientHeight} = ${result}`)
    return result
  }

  computeScrollBottom = () => {
    const ref = this.scrollWindow
    const result = ref.scrollHeight - ref.clientHeight - ref.scrollTop
    debugLog(`computeScrollBottom ${ref.scrollHeight} - ${ref.clientHeight} - ${ref.scrollTop} = ${result}`)
    return result
  }
  
  fixupScrollTop = () => {
    this.scrollWindow.scrollTop = this.computeScrollTop()
  }

  scrollToBottom=()=> {
    this.scrollBottom = 0
    this.fixupScrollTop()
  }

  render() {
    const messages = this.props.messages
    let containerClass = 'uiChatMessagesContainer'
    let marginClass = 'uiChatMarginBottom'
    if (this.props.searchFieldVisible) {
       marginClass += ' uiChatMarginBottomSearchField'
    }
    if (this.props.selectedThread) {
      marginClass += ' uiChatMarginBottomThread'
    }
    let topic
    if (this.props.selectedThread) {
      topic = this.props.selectedThread.topic
    }
    const onSwipeRight = (e) => {
      if (this.props.selectedThread) {
        this.props.selectThread(null)
      }
    }
    return <div key={this.props.key}  className={marginClass} >
             <div className={'uiChatMessages'} ref={this.setScrollWindow}>
               <div key='chatMessagesContainer' className={containerClass} ref={this.setScrollContent}>
                 {messages}
               </div>
             </div>
           </div>
   }

}

class Threads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: props.me.isHelpful()
    }
  }

  componentDidUpdate() {
    if (this.props.threads.length === 0) {
      if (this.state.menuActive) {
        this.setState({
          menuActive: false
        })
      }
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = () => {
      //this.state.menuActive = false
      //this.forceUpdate()
    }
    const selectThread = thread => {
      if (!this.props.me.isHelpful()) {
        this.state.menuActive = false
      }
      this.props.selectThread(thread)
    }
    let menuStyle
    let active = this.state.menuActive || this.props.selectdThread
    if (this.props.me.isHelpful()) {
      active = !this.props.selectedThread
    }
    menuStyle = !active ? { display: 'none' } : null
    const newThread = async () => {
      selectThread({
        id: 'new-thread',
        topic: 'New Topic'
      })
    }
    let className = 'keyboardMenuItem keyboardMenuItemCategory'
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptThreadsMenu' ref={this.setScrollRef}>
        {
          <div className='chatGptThreadMenuThreads' style={menuStyle} >
             {false && <div key={'new.thread'} className={className} onMouseDown={newThread}>
		<div className='keyboardMenuItemIcon'><ReactSVG src={Right}/></div>
		<div className='keyboardMenuItemLabel'>New Topic</div>
                       </div>}
               {
                this.props.threads.map(thread => {
                  const onClick = () => {
                    selectThread(thread)
                  }
                  let trash
                  let date
                  if (this.props.me.isHelpful()) {
                    date = fromNow(thread.lastUpdated)
                    ////debugger
                    trash = async () => {
                      if (this.state.confirmDelete !== thread.id) {
                        this.state.confirmDelete = thread.id
                        console.log("confirmDelete", thread)
                      } else {
                        thread.busy = true
                        this.forceUpdate()
                        await this.props.deleteTask(thread)
                        this.state.confirmDelete = null
                        delete thread.busy
                      }
                      this.forceUpdate()
                    }
                  }
                  let blurb = ''
                  let deleteButton
                  let deleteIcon = thread.busy ? Spin : Trash
                  if (this.state.confirmDelete === thread.id) {
                    const cancelDelete = async () => {
                      this.state.confirmDelete = null
                      this.forceUpdate()
                    }
                    deleteButton = <ClickAwayListener onClickAway={cancelDelete}>
                                     <div className='threadDeleteButtonConfirm' onClick={trash}>Confirm Delete Task<div className='keyboardMenuItemIcon'><ReactSVG src={deleteIcon}/></div></div>
                                   </ClickAwayListener>
                    
                  } else {
                    deleteButton = trash && <div className='threadDeleteButton'><KeyboardButton1 keepFocus action={trash} icon={Trash}/></div>
                  }
                  if (thread.description) {
                    blurb = <div className='keyboardMenuItem taskDescription'>
                              <div className='taskLastTopic'>
                                <div className='taskTopic'>{thread.topic}</div>
                                <div className='taskDate'>{date}</div>
                              </div>
                            </div>
                    return <div className='taskTitle'>
                             <div key={thread.id} className={className}>
                               <div  className={className} onMouseDown={onClick}>
		                 <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
		                 <div className='keyboardMenuItemLabel'>{thread.description}</div>
                               </div>
                                <div className='taskDate'>{date}</div>
                               <div className='keyboardMenuItemRight'>
                                 {deleteButton}
                               </div>
                               </div>
                           </div>
                  } else {
                    blurb = thread.topic
                    return <div key={thread.id} className={className}>
                           <div  className={className} onMouseDown={onClick}>
		             <div className='keyboardMenuItemIcon'><ReactSVG src={Hashtag}/></div>
		             <div className='keyboardMenuItemLabel'>{blurb}</div>
                           </div>
                             <div className='taskDate'>{date}</div>
                             <div className='keyboardMenuItemRight'>
                               {deleteButton}
                             </div>
                         </div>
                  }
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  setScrollRef = ref => {
    if (ref) {
      ref.onscroll = () => {
        //////debugger
        if (ref.scrollHeight - ref.scrollTop === ref.offsetHeight) {
          if (this.props.checkScrollBack) {
            this.props.checkScrollBack()
          }
        }
      }
    }
  }

  
  render() {
    let onClick
    let menuStyle
    if (!this.props.me.isHelpful()) {
      menuStyle = (this.props.selectedThread || !this.state.menuActive) ? { display: 'none' } : null
      onClick = async () => {
        clearTimeout(this.timeout1)
        this.timeout1 = setTimeout(() => {
          this.setState({
            menuActive: !this.state.menuActive
          })
        }, 100)
      }
    }
    //consoleLog("threads", this.props.threads);
    let style
    if (!this.props.me.isHelpful()) {
      style = this.props.slide >= 0.5 ? { display: 'none' } : null
    }
    return <div className='chatGptThreads' style={style}>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuUp : MenuDown} action={onClick}/>
             </div>
             {!menuStyle && this.renderMenu()}
           </div>
  }
}

class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  doRenderMenu = () => {
    const closeMenu = (e) => {
      e.preventDefault()
      this.state.menuActive = false
      this.forceUpdate()
    }
    const selectQuestion = question => {
      this.state.menuActive = false
      this.props.selectQuestion(question)
    }
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptQuestionsMenu'>
        {
            <div className='chatGptQuestionMenuQuestions'>
              {
                this.props.questions.map(question => {
                  let selected = true
                  if (this.props.me.isHelpful()) {
                    selected = question.selected
                  }
                  const onClick = (e) => {
                    e.preventDefault()
                    selectQuestion(question)
                  }
                  let className = 'keyboardMenuItem keyboardMenuItemCategory'
                  return <div key={question.id} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={Question}/></div>
		           <div className='keyboardMenuItemLabel'>{question}</div>
                         </div>
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  renderAutocompletes() {
    let questions = this.filterQuestions()
    debugLog("render autocompletes", questions)
    let className = 'keyboardMenuAutocompletes chatGPTQuestionsAutocomplete'
    const style = {
      position: 'absolute',
      bottom: 41,
      top: 'auto'
    }
    return <div className={className} style={style}>
    {
      questions.map(question => {
        const onClick = () => {
          this.props.ask(question)
        }
        const className= 'keyboardMenuItem keyboardMenuItemAutocomplete'
        return <div className={className} onMouseDown={onClick}>
                 <div className='keyboardMenuItemIcon'><ReactSVG src={UserSaid}/></div>
                 <div className='keyboardMenuItemLabel'>{question}</div>
               </div>
      })
    }
    </div>
  }

  filterQuestions = () => {
    let questions = this.props.questions
    let searchTerm = this.props.searchTerm
    debugLog("searchTerm", searchTerm)
    if (!searchTerm) {
      return []
    }
    searchTerm = searchTerm.toLowerCase()
    const searchTerms = searchTerm.split(/\s+/)
    debugLog('searchTerms', searchTerms.length)
    if (searchTerms.length > 2) {
      return []
    }
    const matches = {}
    let prefixMatch = searchTerms.length > 1
    let filtered = questions.filter(question => {
      if (!question) return false
      let matched = 0
      if (searchTerms.length > 0) {
        const label = question.toLowerCase()
        if (label.startsWith(searchTerm)) {
          matched += 10
          prefixMatch = true
        }
        const terms2 = label.split(/\s+/)
        ////debugLog('terms1', terms1)
        //debugLog('terms2', terms2)
        const allTerms = [terms2]//[terms1, terms2]
        allTerms.forEach((terms, i) => {
          //debugLog('terms', terms, i)
          terms.forEach((term, j) => {
            //debugLog('term', term)
            if (term) {
              searchTerms.forEach((searchTerm, k) => {
                if (searchTerm) {
                  if (term.startsWith(searchTerm)) {
                    matched++
                  }
                  if (term === searchTerm &&
                      j === k && k === 0) {
                    prefixMatch = true
                  }
                }
              })
            }
          })
        })
      } else {
        matched = 0
      }
      debugLog("matched", matched)
      if (matched === 0) return false
      const match = matches[question] || 0
      matches[question] = match + matched
      return true
    })
    if (prefixMatch) {
      filtered = filtered.filter(x => matches[x] >= 10)
    }
    debugLog('filtered', filtered)
    debugLog('matches', matches)
    filtered.sort((x, y) => {
      const w1 = matches[x] || 0;
      const w2 = matches[y] || 0;
      let cmp1 = w2-w1;
      if (cmp1 !== 0) {
        return cmp1;
      }
      return x.localeCompare(y)
    })
    return filtered
  }

  
  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    let menuStyle = (!this.state.menuActive) ? { display: 'none' } : null
    return <div className='chatGptQuestions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
             {this.renderAutocompletes()}
             {!menuStyle && this.renderMenu()}
           </div>
  }
}


class Tools extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
    
  renderMenu = () => {
    return ReactDOM.createPortal(this.doRenderMenu(), getPortal())
  }

  setMenuRef = ref => {
    this.ref = ref
    if (ref) {
      ref.scrollTop = ref.scrollHeight
    }
  }

  doRenderMenu = () => {
    const closeMenu = (e) => {
      e.preventDefault()
      this.state.menuActive = false
      this.forceUpdate()
    }
    const selectTool = tool => {
      this.state.menuActive = false
      this.props.selectTool(tool)
    }
    const tools = this.props.tools
    tools.sort((x, y) => {
      let selected1 = this.props.selectedTools[x.function.name]
      let selected2 = this.props.selectedTools[y.function.name]
      if (!selected1 && selected2) return -1
      else if (selected1) return 1
      const name1 = x.function.name
      const name2 = y.function.name
      return name1.localeCompare(name2)
    })
    let toolIcon = Category
    return <ClickAwayListener onClickAway={closeMenu}>    
      <div className='chatGptQuestionsMenu' ref={this.setMenuRef}>
        {
            <div className='chatGptQuestionMenuQuestions'>
              {
                tools.map(tool => {
                  let selected = this.props.selectedTools[tool.function.name]
                  const onClick = (e) => {
                    e.preventDefault()
                    selectTool(tool)
                  }
                  let className = 'keyboardMenuItem keyboardMenuItemCategory' + (selected ? ' toolSelected' : ' toolUnselected')
                  return <div key={tool.function.name} className={className} onMouseDown={onClick}>
		           <div className='keyboardMenuItemIcon'><ReactSVG src={toolIcon}/></div>
		           <div className='keyboardMenuItemLabel'>{tool.function.description}</div>
                         </div>
                  
                })
              }
            </div>
        }
      </div>
    </ClickAwayListener>
  }

  render() {
    const onClick = async () => {
      setTimeout(() =>  {
        this.setState({
          menuActive: !this.state.menuActive
        })
      }, 50)
    }
    let menuStyle = (!this.state.menuActive) ? { display: 'none' } : null
    return <div className='chatGptQuestions'>
             <div className='keyboardAddButton'>
               <KeyboardButton1 keepFocus icon={this.state.menuActive ? MenuDown : MenuUp} action={onClick}/>
             </div>
             {!menuStyle && this.renderMenu()}
           </div>
  }
}



export class ChatGPT extends Component {

  constructor(props) {
    super(props)
    let selectedModel  = this.props.me.isAskAppOnly() || this.props.me.isBaseModel() ? this.props.isBaseModel ? 'davinci' : 'gpt-3.5-turbo' : (this.props.me.isHelpful() ? 'gpt-4' : 'default')   
    this.state = {
      active: true,
      uploads: [],
      slide: this.props.me.isHelpful() ? 1 : 0,
      edits: new Document(),
      completions: [],
      lang: {
        name: "English",
        dialect: "United States",
        iso: "en-US",
        hasVoice: true
      },
      threadBusy: false,
      swipeIndex: 0,
      assistantModel: localStorage.getItem("assistantModel") || "gpt-3.5-turbo",
      selectedThreads: [],
      selectedVendor: 'openai',
      selectedVendorModel: {openai: 'gpt-3.5-turbo', meta: 'llama-2-70b-chat', mistral: 'mistral-medium'},
      selectedModel,
      hallucinationQuestions: []
    }
  }

  componentDidMount() {
    this.startTime = Date.now()
    setTimeout(this.init, 400)
    setTimeout(() => {
      this.setState({
        canShowBlurb: true
      })
    }, this.props.me.isAskAppOnly() ? 200: 1200)
    if (false && this.props.me.isAskAppOnly()) {
      this.chatSettingsSub = this.props.me.observeChatSettings().subscribe(settings => {
        let { model } = settings
        this.setState({
          selectedModel: model
        })
      })
    }
    if (this.props.me.isAskAppOnly() && !this.props.isBaseModel) {
      this.hallucinationsSub = this.props.me.observeHallucinationQuestions().subscribe(change => {
        const question = change.question
        if (change.type === 'removed') {
          delete this.hallucinationQuestions[question.id]
        } else {
          this.hallucinationQuestions[question.id] = question
        }
        this.setState({
          hallucinationQuestions: Object.values(this.hallucinationQuestions)
        })
      })
    }
  }
  
  hallucinationQuestions = {}

  hasTasks() {
    for (const id in this.tasks) {
      return true
    }
    return false
  }
  

  initTasks = () => {
    if (this.tasksSub) {
      return
    }
    this.tasks = {}
    this.tasksSub = this.props.observeTasks().subscribe(change => {
      const { task } = change
      if (change.type === 'removed') {
        delete this.tasks[task.id]
      } else {
        this.tasks[task.id] = task
        ////debugger
        const selectedTaskId = localStorage.getItem('selectedTaskId')
        if (selectedTaskId == task.id) {
          this.selectThread(task)
        }
      }
      this.forceUpdate()
    })
    this.toolsSub = this.props.me.observeTools().subscribe(change => {
      if (!change) return
      //debugger
      const { type, tool } = change
      if (type === 'removed') {
        delete this.tools[tool.function.name]
      } else {
        this.tools[tool.function.name] = tool
      }
      this.forceUpdate()
    })
  }

  getTools = () => {
    return Object.values(this.tools)
  }

  tools = {}

  currentTaskHasMessages = () => {
    const taskId = this.state.selectedTask.id
    for (const id in this.received) {
      if (this.received[id].task === taskId) {
        return true
      }
    }
    return false
  }

  initSelectedTask = () => {
    if (this.messagesSub) {
      this.messagesSub.unsubscribe()
      this.messsageSub = null
    }
    if (this.threadsSub) {
      this.threadsSub.unsubscribe()
      this.threadsSub = null
    }
    this.received = {}
    this.chatThreads = {}
    const threadOpts = { task: this.state.selectedTask.id  }
    if (this.state.selectedTask) {
      //////debugger
      this.messagesSub = this.props.observeTaskMessages({ task: this.state.selectedTask.id, limit: 20 } ).subscribe(change => {
        consoleLog(change)
        const { message } = change
        let f
        if (change.type !== 'removed') {
          this.received[message.id] = message
          this.parseMessage(message)
        }
        const wasPending = this.received['pending']
        if (wasPending && message.sent === wasPending.sent) {
          delete this.received['pending']
          delete this.cache['pending']
          if (false && this.state.selectedThread && message.topic === this.state.selectedThread.id) {
            this.state.searchResults = this.state.searchResults.filter(x => x.id != 'pending')
            this.state.searchResults.push(message)
          }
          delete this.cache[message.id]
        }
        this.forceUpdate()
      })
      this.threadsSub = this.props.observeChatThreads(threadOpts).subscribe(change => {
        const { thread } = change
        if (change.type === 'removed') {
          delete this.chatThreads[thread.id]
        } else {
          //////debugger
          this.chatThreads[thread.id] = thread
          for (const id in this.received) {
            delete this.cache[id]
          }
        }
        this.forceUpdate()
      })
    }
  }

  init = () => {
    const model = this.state.selectedModel
    consoleLog("init", model)
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    if (this.taskSub) this.taskSub.unsubscribe()
    this.received = {}
    this.chatThreads = {}
    let threadOpts
    if (this.props.me.isHelpful()) {
      this.initTasks()
    } else {
      threadOpts = { model }
      this.messagesSub = this.props.observeChatMessages(model).subscribe(change => {
        consoleLog(change)
        const { message } = change
        let f
        if (change.type !== 'removed') {
          this.received[message.id] = message
          this.parseMessage(message)
          
          const wasPending = this.received['pending']
          if (wasPending && message.sent === wasPending.sent) {
            delete this.received['pending']
            delete this.cache['pending']
            if (this.state.selectedThread && message.topic === this.state.selectedThread.id) {
              this.state.searchResults = this.state.searchResults.filter(x => x.id != 'pending')
              this.state.searchResults.push(message)
            }
          }
          delete this.cache[message.id]
          if (this.state.selectedThread) {
            let found = false
            for (let i = 0; i < this.state.searchResults.length; i++) {
              const prev = this.state.searchResults[i]
              if (prev.id === message.id || prev.id === 'pending') {
                found = true
                this.state.searchResults[i] = message
                break
              }
            }
            if (!found) {
              if (this.state.selectedThread.id === 'new-thread'
                  ||
                  !message.topic
                  ||
                  message.topic === this.state.selectedThread.id) {
                this.state.searchResults.push(message)
              }
            }
          }
        } else {
        delete this.received[message.id]
          delete this.cache[message.id]
          if (this.state.searchResults) {
            this.state.searchResults = this.state.searchResults.filter(x =>
              x.id != message.id && x.inReplyTo != message.id)
          }
        }
        if (!message.inReplyTo) {
          this.restartDelayApologyTimer()
        }
      this.forceUpdate(f)
      })
    }
    if (threadOpts) {
      //this.initThreads(threadOpts)
    }
  }

  chatThreads = {}
  received = {}
  tasks = {}

  restartDelayApologyTimer() {
    clearTimeout(this.delayApologyTimeout)
    this.delayApologyTimeout = setTimeout(() => {
      this.forceUpdate()
    }, 15000)
  }

  componentWillUnmount() {
    if (this.threadsSub) this.threadsSub.unsubscribe()
    if (this.messagesSub) this.messagesSub.unsubscribe()
    clearInterval(this.interval)
    if (this.chatSettingsSub) this.chatSettingsSub.unsubscribe()
    if (this.hallucinationsSub) this.hallucinationsSub.unsubscribe()
  }

  popScrollBottom() {
    debugLog("popScrollBottom: ", this.scrollBottom, " => ", this.savedScrollBottom)
    this.scrollBottom = this.savedScrollBottom || 0
    this.fixupScrollTop()
  }

  getMessages = () => {
    let messages
    messages = Object.values(this.received).filter(x => {
      if (x.from !== this.props.me.self.uid && x.role !== 'tool') {
        return this.received[x.inReplyTo]
      }
      return true
    })
    if (this.props.me.isAskAppOnly()) {
      const reply = {}
      for (const id in this.received) {
        const msg = this.received[id]
        if (msg.inReplyTo) {
          reply[msg.inReplyTo] = msg
        }
      }
      messages = messages.filter(x => {
        let model = x.model
        if (!model) {
          x = reply[x.id]
          if (!x) {
            //////debugger
          }
        }
        if (x && x.model) {
          if (x.model === this.state.selectedModel) {
            return true
          }
        } else {
        }
      })
    }
    const getTs = msg => {
      if (true || !msg.inReplyTo) {
        return msg.ts
      }
      const inReplyTo = this.received[msg.inReplyTo]
      return inReplyTo ? inReplyTo.ts : msg.ts
    }
    messages.sort((x, y) => {
      const t1 = getTs(x)
      const t2 = getTs(y)
      const cmp = t1 - t2
      if (cmp === 0) {
        return x.from === this.props.me.self.uid ? -1 : 1
      }
      return cmp
    })
    if (this.props.me.isHelpful()) {
      let compressed = []
      let userTs = 0
      console.log(messages)
      for (let message of messages) {
        if (message.from === this.props.me.self.uid) {
          userTs = message.ts
        }
        console.log("userTs", userTs)
        let{ collapsed, toolCallSummary, role } = message
        if (false && toolCallSummary) {
          compressed = compressed.filter(x => !(x.tool_call_id || x.tool_calls) || x.ts <= userTs)
          if (role !== 'assistant') {
            const dup = JSON.parse(JSON.stringify(message))
            dup.content = dup.text = toolCallSummary
            message = dup
          }
        }
        if (this.state.assistantView && collapsed) {
          if (!Array.isArray(collapsed)) {
            collapsed = [collapsed]
          }
          for (const segment of collapsed) {
            compressed = compressed.filter(x => {
              if (x.tool_calls && x.tool_calls.find(y => y.function.name === 'collapseTranscript')) {
                return false
              }
              return (x.ts < segment.fromTs || x.ts > segment.toTs)
            })
          }
        }
        compressed.push(message)
      }
      messages = compressed
    }
    return messages
  }

  getThreadMessages = () => {
    if (!this.state.selectedThread) {
      return null
    }
    const messages = this.state.searchResults
    if (!messages) {
      return null
    }
    messages.sort((x, y) => {
      return x.ts - y.ts
    })
    return messages
  }

  getInReplyTo = message => {
    const { inReplyTo } = message
    return this.received[inReplyTo]
  }

  setMessages1 = ref => {
    this.messages1 = ref
  }

  setMessages2 = ref => {
    this.messages2 = ref
  }

  ask = async (topic, q, autoSend) => {
    if (this.received['pending']) {
      return
    }
    if (this.animating) {
      return
    }
    if (topic) {
      let currentTopic = this.getCurrentTopic()
      if (topic != currentTopic) {
        autoSend = false
        const thread = this.chatThreads[topic]
        this.selectThread(thread)
      }
    } else {
      autoSend = false
    }
    const result = this.editor.setText(q)
    if (isDesktop()) {
      this.setState({
        tooltip: ''
      })
    }
    if (autoSend) {
      this.sendChat()
    } 
  }

  getComponents = (message) => {
      const components = {
        p: props => {
          const { node, children } = props
          return children
        },
        pre: PreBlock,
        code: CodeBlock,
        check: CheckMarkComp,
        br: props => {
          return "\n"
        },
        img: ({src, alt}) => {
          return <a className='aiLink' href={src}><img className='aiLinkImg' src={src}/></a>
        },
        a: props => {
          const { node, children } = props
          const { href } = node.properties
          if (!href || !href.startsWith) {
            return null
          }
          const title = ''
          const prefix = "ai://?q="
          let onClick
          let tooltip
          if (href.startsWith(prefix)) {
            let q = (href + ' ' + (title || '')).substring(prefix.length)
            onClick = (e) => {
              const q1 = decodeURIComponentExt(q)
              debugLog('q', q1)
              this.ask(message.topic, q1)
            }
            tooltip = decodeURIComponentExt(q)
          } else {
            tooltip = href
            onClick = e => {
              this.props.me.openWindow(href)
            }
          }
          const nop = () => {
          }
          let enter
          let leave
          if (false && isDesktop()) {
            enter = (e) => {
              this.setState({
                tooltip
              })
            }
            leave = e => {
              this.setState({
                tooltip: ''
              })
            }
          }
          return <div onPointerEnter={enter} onPointerLeave={leave}
                      className='aiLink' onMouseDown={onClick}>{children}</div>
        }
      }
    return components
  }

  renderAISaid = (message, onClick, opts) => {
    let { models, vendors, selectedModel, selectedVendor } = this.getSelectionInfo()
    const components = this.getComponents(message)
    opts = opts || {}
    let { isReply, isBlurb, noSpin, isFactChecked, stop, isTrace, isHallucinated } = opts
    if (message.id === 'blurb') {
      isBlurb = true
      noSpin = true
    }
    const pause = async () => {
      return await this.pauseChat()
      this.forceUpdate()
    }
    let action3
    let icon3
    let clazz3
    if (this.xhr && this.streamSent === message.sent) {
      action3 = pause
      icon3 = Stop
      clazz3 = 'userSaidPause'
    }
    if (message.role === 'tool' || message.tool_calls) {
      isBlurb = true
      if (message.tool_calls) {
        try {
          let busy = message.tool_calls.length
          for (const call of message.tool_calls) {
            for (const id in this.received) {
              const msg = this.received[id]
              if (msg.role === 'tool' &&
                  msg.tool_call_id === call.id) {
                busy--
                break
              }
            }
            if (busy === 0) {
            break
            }
          }       
          noSpin = busy === 0
        } catch (err) {
          console.error(err)
          //////debugger
        }
      } else {
        noSpin = true
      }
    }
    const retry = async () => {
      const orig = this.received[message.inReplyTo]
      if (orig) {
        const text = orig.text
        delete this.received[orig.id]
        delete this.received[message.id]
        this.forceUpdate()
        this.props.deleteChatMessage(orig.id)
        await this.ask(orig.topic, text, true)
      }
    }
    const speak = async () => {
      if (message.speaking || this.state.speaking === message.id) {
        message.speaking = false
        this.state.speaking = false
        this.props.me.cancelSpeak()

        
      } else {
        message.speaking = true
        this.props.me.resetAudioSource()
        markdownToTxt(message.text).then(text => {
          this.props.me.speak(text, this.state.lang.iso).then(() => {
            message.speaking = false
            this.forceUpdate()
          })
        })
      }
      this.forceUpdate()
    }
    
    let { images, role, feedback, lines, text, topic, code, table, isStreaming, tool_calls, toolCallSummary } = message

    const copy = async () => {
      const text = await markdownToTxt(message.text)
      debugLog(text)
      navigator.clipboard.writeText(text)
      await delay(0.5)
    }


    text = text || ''
    if (isStreaming && !text) {
      text = 'Working.'
    }
    isFactChecked = text && !isStreaming && !isBlurb && !message.trace
    isStreaming = isStreaming && (Date.now () - message.ts) < 120000
    const openaiBlurbs = [/[ \n]Is there anything else you would like to know[?]$/,
                          /[ \n]Is there anything else I can help you with[?]$/,
                          /[ \n]I hope this helps[!]$/,
                          /[ \n]Let me know if you have any further questions[.]$/,
                          /[ \n]If you have any further questions or need clarification, feel free to ask!$/,
                          /[ \n]If you have any further questions or need assistance, feel free to ask!$/,
                          /[ \n]Let me know if you'd like more information or have any further questions.$/]
    for (var i = 0; i < 2; i++) {
      for (const blurb of openaiBlurbs) {
        text = text.replace(blurb, '')
      }
    }
    if (tool_calls) {
      let content = ''
      tool_calls.forEach(tool_call => {
        const fun = tool_call['function']
        let args
        try {
          try {
            args = JSON_parse(fun.arguments)
          } catch (err) {
            if (fun.name === 'awaitEvalJavascript') {
              args = fun.arguments
              const s = '"code": '
              const start = args.indexOf(s) + s.length
              const end = args.lastIndexOf('}')
              args = JSON.parse(args.substring(start, end))
            } else {
              content += fun.arguments
              return
            }
          }
          switch (fun.name) {
            case 'createImage':
              {
                let { prompt, style, size, quality } = args
                size = size || '1024x1024'
                quality = quality || 'hd'
                style = style || 'vivid'
                content += `Create a ${style} ${size} ${quality} quality image as follows:\n\n ${prompt}`
              }
              break
            case 'inspectImage':
              content += args.prompt
              break
            case 'emacs':
              content += 'emacs --batch --eval '+args.command
              break
            case 'upsertTaskNotes':
              content += args.upserts.map(upsert => upsert.task + ":\n" + upsert.notes).join('\n')
              break
            case 'system':
              content += args.command
              break
            case 'uploadFileNew':
              content += `Uploading ${args.sourcePath} to ${args.destinationURL}\n`
              break
            case 'osascript':
              content += (args.command || args.script)
              break
            case 'extendScript':
              content += args.script
              break
            case 'uxp':
              content += args.javascript
              break
            case 'fetchFileSummary':
              content += "Summarizing file: " + args.pathname
              break
            case 'writeToTerminal':
              content += args.text
              break
            case 'awaitEvalJavascript':
              content += "Running Javascript code:\n\n  "
              content += '```javascript\n' + args.code + '\n```'
              break
            case 'webExtensionJavascript':
              {
                const script = args.script
                content += args.target + '\n\n'
                //debugger
                content += '```javascript\n' + script + '\n```'
              }
              break
            case 'saveTaskNotes':
              content += `Updating notes for task '${args.task}': ${args.notes}`
              break
            case 'getTaskNotes':
              content += `Checking notes for task: '${args.task}'`
              break
            case 'searchTaskNotes':
              content += `Search notes for task: ${args.query}`
              break
            case 'getAccessToken':
              content += 'Getting access token for service: ' + args.service + " with scope(s) " + args.scopes.join(', ')
              break
            default:
              content += fun.name + '('
              let sep = ''
              for (const arg in args) {
                content += sep
                content += arg + ': ' + JSON.stringify(args[arg])
                sep = ', '
              }
              content += ')'
          }
        } catch (err) {
          //debugger
          consoleLog(fun.arguments)
          console.error(err)
          args = fun.arguments
          content += args + ')'
        }
        content += '\n'
      })
      text = content
    }
    //consoleLog('isFactChecked', isFactChecked, message)
    const factCheck = async () => {
      const suggestion = await this.factCheck(message.id)
      if (suggestion) {
        this.ask(topic, suggestion, false)
      }
    }
    let showSpeaker = true
    let showRetry = false
    let icon1 = AISaid
    if (this.props.me.isAskAppOnly()) {
      let model = message.model
      if (!model) {
        if (Date.now () - message.ts > 60000) {
          model = OpenAI
        } else {
          model = this.state.selectedModel
        }
      }
      if (isHallucinated || this.props.isBaseModel) {
        icon1 = selectedVendor.icon
      }
      if (!isBlurb) switch (model) {
        case 'davinci':
        case 'llama-2-70b':
        case 'mixtral-8x7b':
          icon1 = selectedVendor.icon
      }
    }
    if (this.props.me.isHelpful()) {
      if (message.tool_call_id || message.tool_calls) {
        //icon1 = OpenAI
        icon1 = null
      }
      if (lines) {
        lines = [ lines[lines.length-1]]
        text = text || ''
        text += '```\n'+lines.join('\n')+'\n```'
      }
      if (feedback) {
        text = text || ''
        text += '```\n'+feedback+'\n```'
      }
    }
    let icon2 = Copy
    let action = copy
    let className = 'keyboardEditDocument'
    let serverError = topic === 'system-error'
    if (serverError) {
      icon1 = Alert
      className = 'keyboardEditDocument keyboardEditDocumentError'
      icon2 = null
      action = null
      onClick = null
      showSpeaker = false
      showRetry = true
    } else {
      if (isBlurb) {
        showSpeaker = false
        if (!serverError) {
          if (!noSpin) {
            icon2 = Spin
          } else {
            icon2 = null
          }
          action = async () => {}
          className += ' aiComment'
        } else {
          className += ' aiCommentError'
        }
      }
      else if (isReply) {
        className += ' aiReply'
      } else if (false && isStreaming) {
        icon2 = Stop
        className == ' aiStop'
        action = async () => {
        }
      }
    }
    if (role == 'system') {
      className += ' aiComment'
    }
    if (isStreaming) {
      icon2 = Spin
      showSpeaker = false
    }
    if (isTrace) {
      showSpeaker = false
      className += ' aiTrace'
    }
    if (isHallucinated) {
      showSpeaker = false
      className += ' aiHallucinated'
    }
    if (images) {
      showSpeaker = false
      action = async () => {
        function copyImageUrlToClipboard(url) {
          // Create a temporary input element
          var tempInput = document.createElement("input");
          tempInput.value = url; // Set its value to the URL
          document.body.appendChild(tempInput); // Append it to the body
          
          // Select the URL
          tempInput.select();
          tempInput.setSelectionRange(0, 99999); // For mobile devices
          
          // Copy the text inside the input
          document.execCommand("copy");
          
          // Remove the temporary input element
          document.body.removeChild(tempInput);
        }
        copyImageUrlToClipboard(images[0])
        await delay(0.5)
      }
    }
    const renderBody = () => {
      let markdown = replaceImgWithMarkdown(text || '')
      const linkReg = /\[([^\]]+)\]\(([^)]+)\)/g
      markdown = markdown.replace(linkReg, (match, linkText, url) => {
        return `[${decodeURIComponentExt(linkText)}](${encodeURIExt(url)})`
      })
      if (true) {
        const before = markdown
        markdown = markdown.replace(/\n\n/g, '\n&nbsp;&nbsp;\n')
        //console.log("escaping newlines", before, markdown)
        return <Markdown components={components}>{markdown}</Markdown>
      }
      markdown = markdown.replace(/<<[^>]+>>/g, '') // hack: <<...>> caused Markdown to hang because it has a bad regex
      return <Markdown children={markdown} options={{
                         overrides: components
                       }}/>
      
    }
    const renderBody1 = () => {
      if (images) {
          return <div className="aiSaidImages">
                 {images.map(url => <img src={url}/>)}
           </div>
      }
      return <div className='keyboardEditDocumentTextInline'>
               {message.code ? renderCode(message.code, this.copyToClipboard)  : renderBody()}
             </div>
    }
    let speakerClassName = (message.id === this.state.speaking || message.speaking ? ' iconSpeakerActive' : '')
    let leftIconClassName = 'chatGPTLeftIcon copyAISaid'
    if (showRetry) {
      leftIconClassName += ' retryAISaid'
    }
    return <div key={message.id} className={className} onClick={onClick}>
             <div className='keyboardEditInstructionLeftIcon'>
               <ReactSVG src={icon1}/>
             </div>
             <div className={'keyboardEditDocumentText'}>
               {renderBody1()}
             </div>
             <div className={leftIconClassName}>
               {!showRetry && <KeyboardButton1 keepFocus icon={icon2} action={action}/>}
               {!isBlurb && this.props.me.isAskAppOnly() && <KeyboardButton1 keepFocus icon={CheckMark} action={factCheck}/>}
               {showSpeaker && <KeyboardButton1 className={speakerClassName} keepFocus icon={Speaker} action={speak}/>}
               {showRetry && <KeyboardButton keepFocus label="Retry" icon={Send} action={retry}/>}
               {!isBlurb && action3 && <div key='pause' className={'keyboardEditInstructionLeftIcon ' + clazz3}>
                                                <KeyboardButton1 keepFocus icon={icon3} action={action3}/>
                           </div>}
             </div>
           </div>
  }


  cache = {}


  resolveTopic = message => {
    const task = this.tasks[message.task]
    const thread = this.chatThreads[message.topic]
    if (thread) {
      return thread
    }
    if (task) {
      return {
        id: task.lastTopic.topicId,
        topic: task.lastTopic.topic,
        lastUpdated: task.lastUpdated
      }
    }
  }
  
  renderChatMessage = (message, prev, next, mergedBody, state) => {
    const cached = this.cache[message.id]
    if (cached) return cached
    const thread = this.resolveTopic(message)
    const topic = thread ? thread.topic : ''
    const msg = message
    const time = Number(msg.ts);
    let sameDay = "h:mm a";
    let sameElse =  "MM/DD/YYYY \\a\\t ";
    const sameMinute = (t1, t2) => {
      const d1 = new Date(t1);
      const d2 = new Date(t2);
      return (d1.getYear() === d1.getYear() && d1.getMonth() == d2.getMonth() && d1.getDate() === d2.getDate() && d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes());
    };
    if ((prev && sameMinute(time, prev)) || (next && sameMinute(time, next))) {
      sameDay = "h:mm:ss a";
    }
    const timestamp =  moment(time).calendar(null, {
      sameDay: sameDay,
      //lastDay: "[Yesterday] "+sameDay,
      //lastWeek: "[Last] dddd "+sameDay,
      sameElse: sameElse+ sameDay,
    })
    let className = 'chatGptChatMessageHeader'
    if (message.id !== 'blurb') {
      if (message.from === this.props.me.self.uid) {
        className += ' chatMessageFromUser'
      } else {
        className += ' chatMessageFromGpt'
      }
    }
    const fromMe = message.from === this.props.me.self.uid
    const noTime =  !fromMe || (next && (prev && msg.ts - prev.ts < 15 * 1000 * 60));
    let noTopic = prev && (!fromMe || state.lastTopic === message.topic)
    if (message.topic) {
      state.lastTopic = message.topic
    }
    if (!noTopic && next && next.text ===  topic) {
      noTopic = true
    }
    let className0 = 'chatGptChatMessage'
    if (message.topic === 'system-error') {
      className0 += ' chatGptChatMessageError'
    }
    const onSwipeLeft = e => {
      if (!message.code) {
        if (thread && !this.state.selectedThread) {
          this.selectThread(thread)
        }
      }
    }
    const onSwipeRight = e => {
      if (!message.code) {
        if (this.state.selectedThread) {
          this.selectThread(null)
        }
      }
    }
    const msgBody = this.renderChatMessageBody(message, prev, next, mergedBody)
    if (msgBody.length === 0) {
      return msgBody
    }
    const Swipe = props => {
      if (this.props.me.isHelpful()) {
        return props.children
      }
      return <Swiper onSwipeLeft={props.onSwipeLeft} onSwipeRight={props.onSwipeRight}>
               {props.children}
             </Swiper>
    }
    const result = <div key={message.id} className={className0} >
                     <Swipe>
                       {!(noTime && noTopic) && <div className={className}>
                                                  <div className='chatGptChatMessageHeaderTopic'>{noTopic ? '' : '# ' + topic}</div>
                                                  {!noTime &&<div className='chatGptChatMessageTimestamp'>{timestamp}</div>}
                                                </div>}
                       <div className='chatGptChatMessageBody'>
                         {msgBody}
                       </div>
                     </Swipe>
                   </div>
    if (this.props.me.isHelpful()) {
      this.cache[message.id] = result
    } else if (this.state.selectedThread && this.wasAnswered(message)) {
      this.cache[message.id] = result
    }
    return result
  }

  isRecent = message => {
    const now = Date.now()
    return now - message.ts < 60000;
  }

  isToolCallComplete = (tool_call_id) => {
    const reply = Object.values(this.received).find(x => x.tool_call_id === tool_call_id)
    return reply
  }

  wasAnswered = (message) => {
    const reply = Object.values(this.received).find(x => x.inReplyTo === message.id)
    if (reply && reply.text) {
      if (message.stream) {
        message.stream = null
        delete this.cache[message.id]
      }
    }
    return reply
  }

  purgeCache = message => {
    console.log('purge', message)
    const id = message.id
    delete this.cache[id]
    delete this.received[id]
    const replies = Object.values(this.received).filter(x => x.inReplyTo === id)
    console.log("purging replies", replies)
    replies.forEach(reply => {
      delete this.cache[reply.id]
      delete this.received[reply.id]
    })
    const dangling = Object.values(this.received).filter(x => x.from !== this.props.me.self.uid && !x.inReplyTo)
    console.log('dangling', dangling)
  }
  
  renderChatMessageBody = (message, prev, next, mergedBody) => {
    const components = this.getComponents(message)
    if (message.from === this.props.me.self.uid) {
      const del = async () => {
        await this.props.me.deleteChatMessage(message.id)
        this.purgeCache(message)
        if (this.state.searchResults) {
          this.state.searchResults = this.state.searchResults.filter(x => {
            return x.id !== message.id && x.inReplyTo !== message.id
          })
          if (!this.props.me.isHelpful() && this.state.searchResults.length === 0) {
            this.selectThread(null)
          } else {
            this.forceUpdate()
          }
        } else {
          this.forceUpdate()
        }
      }
      const now = Date.now()
      if (message.reaction && !message.reaction.split) {
        //////debugger
      }
      let action1
      let icon1
      let clazz1 = ''
      if (message.id !== 'pending') {
        action1 = del
        icon1 = Trash
        clazz1 = 'userSaidDel'
      }
      let markdown = replaceImgWithMarkdown(message.text || '')
      markdown = markdown.replace(/\n\n/g, '\n&nbsp;&nbsp;\n')
      
      let user = <div key='usermsg' className='keyboardEditInstruction'>
               <div className='keyboardEditIconAndInstruction'>
                 <div className='keyboardEditInstructionLeftIcon'>
                 <ReactSVG src={UserSaid}/>
                 </div>
                 <div className='keyboardEditInstructionText'>
                   <Markdown components={components}>{markdown}</Markdown>
                 </div>
                 {action1 && <div key='del' className={'keyboardEditInstructionLeftIcon ' + clazz1}>
                                                <KeyboardButton1 keepFocus icon={icon1} action={action1}/>
                          </div>
                 }
               </div>
               {message.reaction && <div key='reactions' className='chatGPTMessageReactions'>
                                      {
                                        message.reaction.split().map(reaction => {
                                          return <div className='chatGPTMessageReaction'>{reaction}</div>
                                        })
                                      }
                                    </div>}
                  </div>
      if ((this.isRecent(message) && !this.wasAnswered(message)) || message.stream) {
        let other
        if (message.stream) {
          other = this.renderAISaid({
            id: message.id + '-reply',
            ts: Date.now(),
            text: message.stream,
            topic: message.topic,
            isStreaming: true,
          })
        } else {
          let text = 'Contacting server.'
          if (now - message.ts > 15000) {
            text = 'I apologize for the delay. I am waiting for the server to process your request.'
          }
          if (message.reaction) {
            text = 'Working.'
          }
          other = this.renderAISaid({
            id: 'blurb-spinner',
            ts: message.ts,
            text,
          }, null, { isBlurb: true, isTrace: true, noSpin: false })          
        }
        return [user, other]
      }
      return user
    }
    const output = []
    let { name, lines, outputTs, trace, progress, text, id, isStreaming, hallucinated, model, tool_calls, tool_call_id, role, toolCallSummary } = message
    outputTs = outputTs || Date.now()
    if (isStreaming && !text ) {
      text = 'Working.'
    }
    let images
    if (tool_call_id && name === 'createImage') {
      try {
        images = []
        let sep = '\nRevised Prompt:\n'
        const json = JSON.parse(text)
        text = json.note + ":\n"
        for (const image of json.images) {
          const { url, revised_prompt } = image
          text += sep
          text += revised_prompt
          sep = '\n'
          images.push(url)
        }
        //trace = text
      } catch (err) {
        images = null
        console.error(err)
      }
    }
    let noSpin = text
    const isLast = !next
    consoleLog("isLast", message)
    if (isLast && (tool_call_id || tool_calls || from === this.props.me.self.uid)) {
      if (!tool_calls || !text) trace = 'Working.'
      noSpin = false
    } else {
      if (!this.props.me.shouldTraceChatGPT()) {
        trace = null
        if (!text) {
          let start = this.progressMessage[id]
          if (!start) {
            start = Date.now()
            this.progressMessage[id] = start
          }
          trace = progress
        } else if (isStreaming || this.progressMessage[id]) {
          const now = Date.now()
          const start = this.progressMessage[id]
          if (now - start < 1000) {
            trace = progress
          }
        } else {
          delete this.progressMessage[id]
        }
      }
    }
    if (tool_calls) {
      trace = text
    }
    if (toolCallSummary) {
      output.push(this.renderAISaid({
        id: 'toolCallSummary',
        text: toolCallSummary,
        role: 'tool',
        tool_call_id: true
      }, null, {
        isBlurb: true, noSpin: true
      }))
    }
    if (hallucinated && !this.props.isBaseModel) {
      output.push(this.renderAISaid({
        id: 'hallucinated',
        text: hallucinated, topic: message.topic,
        model
      }, null, {
        isBlurb: true, noSpin: text, isTrace: true, isHallucinated: true, 
      }))
    }
    if (text && !tool_calls) {
      output.push(this.renderAISaid(message, null))
    }
    if (trace) {
      output.push(this.renderAISaid({
        id: 'trace', text: trace, topic: message.topic, isStreaming: message.isStreaming, model
      }, null, {
        isBlurb: true, noSpin, isTrace: true
      }))
    }
    if (images) {
      output.push(this.renderAISaid({ images }, null))
    }
    if (tool_calls) {
      output.push(this.renderAISaid(message, null))
    }
    if (false) {
      const collapse = tool_calls && tool_calls.find(x => x.function.name === 'collapseTranscriptSegment')
      if (collapse) {
        const args = JSON.parse(collapse.function.arguments)
        console.log("ARGS", args)
        let { segment, segments } = args
        if (!segments) {
          segments = [segment]
        }
        const summary = segments.map(segment => segment.summary).join('\n')
        output.push(this.renderAISaid({text: summary, isBlurb: true}, null))
      }
    }
    return output
  }

  progressMessage = {}

    
  isTextMessage = (message, withReactions) => {
    return true
  }
  
  renderMessages = rawMessages => {
    consoleLog("messages", rawMessages)
    let messages
    const mergedBody = {}
    if (false) {
      messages = []
      let i = 0
      while (i < rawMessages.length) {
        let msg = rawMessages[i]
        if (this.isTextMessage(msg, true)) {
          let j = i + 1
          let lines 
          let ts = msg.ts
          let last
          let body
          while (j < rawMessages.length) {
            const next = rawMessages[j]
            if (next.from !== msg.from || !this.isTextMessage(next, false)) {
              break
            }
            if (next.ts - msg.ts > 15 * 1000 * 60) {
              break
            }
            if (!lines) {
              lines = [msg]
            }
            lines.push(next)
            last = next
            j++
            if (last.reaction && last.reaction.length > 0) {
              break
            }
          }
          if (last) {
            mergedBody[last.ts] = lines
            i = j-1
            msg = last
          }
          messages.push(msg)
          i++
        }
      }
    } else {
      messages = rawMessages
    }
    const state = {}
    return messages.map((msg, i) => {
      const prev = i > 0 ? messages[i-1] : null
      const next = i + 1 < messages.length ? messages[i+1] : null
      return this.renderChatMessage(msg, prev, next, mergedBody, state)
    })
  }

  setSliderContainer = ref => {
    this.sliderContainer = ref
  }

  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const popup = this.state.popup ? this.state.popup() : null
    const content = this.renderContent()
    return  <BnPage me={this.props.me} subpage={subpage} popup={popup} safeArea={true}>
              {content}
            </BnPage>
    
  }

  shareThread = async (thread, messages) => {
    await this.props.me.shareThread(thread, messages)
  }

  getSelectionInfo = () => {
    let models
    let openAIModelLabel = this.props.isBaseModel ? "DAVINCI" : "GPT-3.5"
    let openAIModel = "gpt-3.5-turbo"
    let openAIInstruct = "gpt-3.5-turbo-instruct"
    let llamaModel = "llama-2-70b-chat"
    if (this.props.isBaseModel) {
       openAIModel = "davinci"
       llamaModel = "llama-2-70b"
    }
    const selectModel = model => {
      if (model === this.state.selectedModel) return
      if (this.state.selectedThread) {
        this.selectThread(null)
      }
      this.state.selectedVendorModel[this.state.selectedVendor] = model
      this.setState({
        selectedModel: model
      }, this.init)
      this.props.me.saveChatSettings({
        model
      })
    }
    switch (this.state.selectedVendor) {
      case 'openai':
        models = [
          !USE_GPT4 ? 
            {
              label: isMobile() ? 'Chat': 'GPT-3.5',
              selected: this.state.selectedModel === openAIModel,
              select: () => { selectModel(openAIModel) },
              //            icon: OpenAI
              title: "OpenAI GPT-3.5 Turbo",
              getIcon: () => OpenAI
            }
          :
          {
            label: 'GPT-4',
            selected: this.state.selectedModel === 'gpt-4',
            select: () => { selectModel('gpt-4') },
            title: "GPT-4 Turbo",
            //            icon: OpenAI
            getIcon: () => OpenAI
          },
          {
            label: isMobile() ? 'Base' : "DAVINCI",
            selected: this.state.selectedModel === 'davinci',
            select: () => { selectModel('davinci') },
            title: "OpenAI Davinci-002",
            //            icon: OpenAI
            getIcon: () => OpenAI
          }
        ]
        break
      case 'meta':
        models = [
          {
            label: 'Chat',
            selected: this.state.selectedModel === 'llama-2-70b-chat',
            select: () => { selectModel('llama-2-70b-chat') },
            title: "Meta Llama-2 70B Chat",
            // icon: Meta
            getIcon: () => Meta
          },
          {
            label: "Base",
            selected: this.state.selectedModel === 'llama-2-70b',
            select: () => { selectModel('llama-2-70b') },
            title: "Meta Llama-2 70B Base",
            //icon: Meta
            getIcon: () => Meta
          }]
        break
      case 'mistral':
        models = [
          {
            label: isMobile() ? 'Chat' : "Medium",
            selected: this.state.selectedModel === 'mistral-medium',
            select: () => { selectModel('mistral-medium') },
            // icon: Meta
            title: "Mistral Medium",
            getIcon: () => Mistral
          },
          {
            label: isMobile() ? 'Base' : '8 x 7b',
            selected: this.state.selectedModel === 'mixtral-8x7b',
            select: () => { selectModel('mixtral-8x7b') },
            //icon: Meta
            title: "Mixtral 8 x 7b",
            getIcon: () => Mistral
          }]
        break
        
    }
    const selectVendor = vendor => {
      if (vendor === this.state.selectedVendor) return
      if (this.state.selectedThread) {
        this.selectThread(null)
      }
      this.setState({
        selectedVendor: vendor,
        selectedModel: this.state.selectedVendorModel[vendor]
      }, this.init)
      this.props.me.saveChatSettings({
        vendor
      })
    }
    const vendors = [
      {
        label: "OpenAI",
        selected: this.state.selectedVendor === 'openai',
        select: () => { selectVendor('openai') },
        icon: OpenAI
      },
      {
        label: "Meta",
        selected: this.state.selectedVendor === 'meta',
        select: () => { selectVendor('meta') },
        icon: Meta
      },
      {
        label: "Mistral",
        selected: this.state.selectedVendor === 'mistral',
        select: () => { selectVendor('mistral') },
        icon: Mistral
      }
    ]
    const vendorSelected = vendors.find(x => x.selected)
    const modelSelected = models.find(x => x.selected)
    return { vendors, models, selectedVendor: vendorSelected, selectedModel: modelSelected }
  }
  
  
  renderContent() {
    let { vendors, models, selectedVendor, selectedModel } = this.getSelectionInfo()
    let title
    let rawMessages = this.getMessages()
    consoleLog("rawMessages", rawMessages)
    
    let threaded
    if (this.props.me.isHelpful()) {
      if (this.state.searchTerm) {
        threaded = this.getThreadMessages() || []
      } else {
        threaded = rawMessages
      }
    } else {
      threaded = this.getThreadMessages() || []
    }
    let buttonIcon = Send
    let buttonAction = async () => {
      return await this.sendChat()
    }
    let copyAction = this.editor && this.editor.isEmpty() ? null: this.copy
    let shareAction = null
    let busy = false
    let questions = []
    let placeholder = 'Ask me anything'
    let currentTopic
    if (this.state.slide > 0.5) {
      currentTopic = this.state.selectedThread ? this.state.selectedThread.id : null
    }
    let newTopicButton
    if (rawMessages.length > 0) {
      let message = rawMessages[rawMessages.length-1]
      //busy = message.from && !this.wasAnswered(message, true)
      if (!currentTopic) {
        currentTopic = message.topic
      }
    }
    if (this.state.selectedThread && this.state.selectedThread.id) {
      shareAction = () => this.shareThread(this.state.selectedThread, threaded)
    }
    busy = this.state.threadBusy
    let buttonLabel = 'Send'
    if (currentTopic && currentTopic !== 'new-thread') {
      const newTopic = async () => {
        return this.selectThread({
          id: 'new-thread',
          topic: 'New Topic'
        })
      }
      if (currentTopic) {
        const thread = this.chatThreads[currentTopic]
        if (thread) {
          placeholder = '# ' +thread.topic
        }
      }
      newTopicButton = <KeyboardButton className='newTopicButton' label={'Ask'} keepFocus action={newTopic} icon={Hashtag}/>
    }
    let messages = rawMessages
    let showSearchField = (messages.length > 0 || this.props.me.isHelpful() && this.hasTasks())
    if (!this.props.me.isHelpful()) {
      if (messages.length === 0 ||
          ((this.props.me.isAskAppOnly()) && !this.state.selectedThread && !this.state.searchTerm)) {
        let isChat = true
        switch (this.state.selectedModel) {
          case 'davinci':
          case 'llama-2-70b':
          case 'mixtral-8x7b':
            isChat = false
        }
        const vendor = this.props.selectedVendor || {}
        const model = this.props.selectedModel || {}
        
        if (this.state.canShowBlurb) {
          let followUpQuestions
          let blurb
          if (!this.props.isBaseModel) {
            const questions = this.state.hallucinationQuestions.slice(0, 3)
            const links = questions.map(x => {
              const q = x.question
              consoleLog("QUESTION", q)
              return  `\n- [${q}](ai://?q=${q})`
            })
            consoleLog("LINKS", links)
            if (!this.props.me.isAskAppOnly()) {
              followUpQuestions = QUESTIONS
              blurb = generateBlurb()
            } else {
              if (true) {
                blurb =
                  `${false && messages.length == 0 ? 'Welcome! ' : ''}This is a proof-of-concept demonstration of Attunewise steering technology to reduce hallucinations in LLM's. It isn't perfect but keep in mind it's just GPT-3.5-turbo fine-tuned on less than 100 examples.\n
${true || isChat ? ``: `Yet we are able steer instruction following with zero fine tuning, as well as turn-based chat, reduce hallucinations, and align with human values even in the latter case. `}Below are some example questions which trigger hallucinations in ${models[0].title}. Tap the menu for more examples or, of course, you can enter your own.${!isChat ? `<br/><br/>`: `&nbsp;For each question a non-hallucinatory answer will be provided along with the default answer (potentially containing hallucinations).<br/><br/>`}
                 ${links.join('')}`
                let clazz
                let base
                let ins
                switch (this.state.selectedVendor) {
                  case 'openai':
                    base = 'Davinci'
                    ins = 'GPT-3.5-turbo'
                    clazz = 'GPT-3'
                    break
                  case 'meta':
                    base = 'Llama-2 Base'
                    ins = 'Llama-2 Chat'
                    clazz = 'Llama-2'
                    break
                  case 'mistral':
                    base = 'Mixtral 8x7b Base'
                    ins = 'Mistral Medium'
                    clazz = 'Mistral'
                    break
                }
                const blurbPage2 = `This is a demonstration of Attunewise steering technology applied to ${base}, a ${clazz} pretrained model, which has no fine-tuning whatsover - yet we are able steer instruction following and turn-based chat. <br/><br/>Obviously, its conversational abilities are rather limited compared to extensively fine-tuned models like ${ins}, nevertheless our proprietary methods allow us to query and interact with the raw pre-trained model in essentially arbitrary ways, no longer limited to few-shot prompts or multiple choice questions. This capability is key to our approach in understanding and eliminating hallucinations.`
                if (!isChat) {
                  blurb = blurbPage2
                }
              } else {
                followUpQuestions = QUESTIONS
                blurb = generateBlurb(`${messages.length == 0 ? 'Welcome! ' : ''}This is a demonstration of Attunewise steering technology applied to pre-trained LLM's that have <i>no fine-tuning whatsoever</i>.${false ? `` : `<br/><br/>Yet we are able steer turn-based chat, reduce hallucinations, and align with human values.`}<br/><br/> `)
              }
            }
            
          } else {
            if (this.props.me.isBaseModel()) {
              blurb = generateBlurb("Welcome! You are conversing with Davinci. It may not always be factually accurate, but it can be very entertaining, thoughtful, and skilled.<br/><br/>")
            }
          }
          if (blurb) blurb = blurb.replaceAll("<br/><br/>", "\n\n")
          let ts
          if (!messages.length) {
            ts = Date.now()
          } else {
            ts = this.startTime
          }
          const getTs = msg => {
            if (!msg.inReplyTo) {
              return msg.ts
            }
            const inReplyTo = this.received[msg.inReplyTo]
            return inReplyTo ? inReplyTo.ts : msg.ts
          }
          messages.push({
            ts: ts,
            text: blurb,
            id: 'blurb',
            followUpQuestions
          })
          messages.sort((x, y) => {
            const t1 = getTs(x)
            const t2 = getTs(y)
            const cmp = t1 - t2
            if (cmp === 0) {
              return x.from === this.props.me.self.uid ? -1 : 1
            }
            return cmp
          })
        } else {
          busy = true
        }
      }
    }
    if (!this.state.selectedThread) {
       messages = this.state.searchResults || rawMessages
    }
    let questionsTopic
    if (!busy) {
      let msgs = [].concat(this.state.slide > 0.5 ? threaded : messages).reverse()
      if (msgs.length > 0) {
        const dup = {}
        let i = 0
        let message = msgs[i]
        consoleLog("message", message)
        while (!message.followUpQuestions && (i + 1) < msgs.length) {
          i++
          message = msgs[i]
        }
        msgs = msgs.slice(i)
        const topic = message.topic
        for (const x of msgs) {
          if (x.topic !== topic) {
            break
          }
          if (x.from === this.props.me.self.uid) {
            dup[x.text] = true
          } else if (x.followUpQuestions) {
            questions.push(x.followUpQuestions)
          }
        }
        questionsTopic = topic
        questions = questions.reverse().flatMap(x => x)
        questions = questions.filter(x => {
          if (!x) return false
          if (!dup[x]) {
            dup[x] = true
            return true
          }
          return false
        })
        consoleLog('questions', topic, questions.length)
      }
    }
    const x = this.state.slide * -(Math.min(window.innerWidth, 600) - 10)
    const sliderStyle = {
      transform: `translate(${x}px, 0)`
    }
    let index = this.state.swipeIndex
    let sliderClassName = 'chatMessagesSlider'
    if (this.state.selectedThread) {
      sliderClassName += ' chatMessagesSliderEnabled'
    }

    let showKeyboard = this.state.showKeyboard
    let menu
    if (this.state.hallucinationQuestions && !this.props.isBaseModel) {
      questionsTopic = null
      questions = this.state.hallucinationQuestions.map(x => x.question)
      questions.sort()
    }
    if (questions.length > 0) {
      const ask = q => this.ask(questionsTopic, q, true)          
      menu = <Questions me={this.props.me} ask={ask} searchTerm={this.state.questionSearchTerm} questions={questions} selectQuestion={ask} editorHeight={this.state.editorHeight}/>
    }
    const sfcStyle = {
      height: this.state.selectedThread ? 40 : 80
    }
    let style = !isDesktop() &&this.state.orient === 'landscape' ? { display: 'none' } : null
    let style2
    let style3
    if (this.props.isOpenAI) {
      style3 = style2 = { visibility: 'hidden' }
    }
    if (this.props.me.isHelpful()) {
      style3 = { visibility: 'hidden' }
    }
    if (this.props.me.isAskAppOnly()) {
      title = 'Attunewise.ai'
      if (selectedVendor && selectedModel) {
        title = <div className='chatGPTModelHeader'>

                  <div className='keyboardRadioButtonIcon'><ReactSVG src={selectedVendor.icon}/></div>
                  {selectedModel.title}
                </div>
      }
    }
    let bottomRow
    if (models && !this.isKeyboardShowing() &&
        this.props.me.isAskAppOnly()) {
      bottomRow = <div className='buttonz'>{<RadioButtons small={true} label='Vendors' buttons={vendors}/>}<RadioButtons label='Models' buttons={models}/></div>
    }
    if (this.props.me.isHelpful()) {
      const selectModel = model => {
        this.setState({
          assistantModel: model
        })
        localStorage.setItem('assistantModel', model)
      }
      const models = [
        {
          label: 'GPT-3.5',
          selected: this.state.assistantModel === 'gpt-3.5-turbo',
          select: () => { selectModel('gpt-3.5-turbo') },
          title: "GPT-3.5 Turbo",
          getIcon: () => OpenAI
        },
        {
          label: 'GPT-4',
          selected: this.state.assistantModel === 'gpt-4',
          select: () => { selectModel('gpt-4') },
          title: "GPT-4 Turbo",
          getIcon: () => OpenAI
        }
      ]
      const selectedVendor = {
        icon: OpenAI
      }
      const selectedModel = {
        title: models.find(x => x.selected).title
      }
      const toggle = () => {
        this.setState({
          assistantView: !this.state.assistantView
        })
      }
      let className ='chatGPTModelHeader chatGPTModelHeaderClickable'
      if (this.state.assistantView) {
        className += ' chatGPTModelHeaderAssistantView'
      }
      title = <div className={className} onClick={toggle}>
                <div className='keyboardRadioButtonIcon'><ReactSVG src={selectedVendor.icon}/></div>
                {selectedModel.title}
              </div>
      bottomRow = <div className='buttonz'><RadioButtons label='Models' buttons={models}/></div>
    }
    let inputControlStyle
    if (this.props.me.isHelpful()) {
      const selectTool = tool => {
        console.log('selectTool', tool)
        this.editor.setText(tool.function.name)
      }
      if (this.hasTasks()) {
        messages = []
      }
      inputControlStyle = { opacity: this.state.slide }
      const last = [].concat(threaded).reverse().find(x => x.from === this.props.me.self.uid && x.selectedTools || x.inReplyTo)
      if (last && last.inReplyTo) {
        const req = this.received[last.inReplyTo]
        if (req) {
          let { selectedTools } = req
          if (selectedTools) {
             const allTools = this.getTools()
              const selection = {
              }
              selectedTools.forEach(name => {
              selection[name] = true
            })
            menu = <Tools me={this.props.me}  searchTerm={''} tools={allTools} selectedTools={selection} selectTool={selectTool} editorHeight={this.state.editorHeight}/>
          }
        }
      }
    }
    return <div className='chatGPT'>
             <div className='keyboardHeader' style={style}>
               <div style={style2}><KeyboardButton1 icon={Left} action={this.goBack}/></div>
               <KeyboardTitle title={title}/>
               <div style={style3} className='keyboardHeaderButton keyboardHeaderButtonCancel' onMouseDown={this.cancel}>
                 <ReactSVG src={Cross}/>}
               </div>
             </div>
             <div className='chatMessagesSliderContainer' ref={this.setSliderContainer}>
               <div className={sliderClassName} style={sliderStyle}>
                 <Messages slide={this.state.slide}
                           searchFieldVisible={showSearchField}
                           selectedThread={null}
                           selectThread={this.selectThread}
                           onCreate={this.setMessages1}
                           key='main'
                           messages={this.renderMessages(messages)}
                           checkScrollBack={this.checkScrollBack}/>
                 <Messages onCreate={this.setMessages2}
                           key='threaded'
                           busy={this.state.threadBusy && !this.state.searchTerm}
                           selectedThread={this.state.selectedThread}
                           selectThread={this.selectThread}
                           slide={this.state.slide}
                           searchFieldVisible={showSearchField}
                           checkScrollBack={this.checkScrollBack2}
                           messages={this.renderMessages(threaded)}/>
               </div>
             </div>
             <div className={'chatGPTInput' + (menu ? ' chatGPTInputWithMenu' : '')} style={inputControlStyle}>
               <InputControl onCreate={this.setInputRef}
                             busy={busy}
                             onDrop={this.onDrop}
                             onPaste={this.onPaste}
                             onKeyDown={this.onKeyDown}
                             placeholder={placeholder} me={this.props.me}
                             onSetEditor={this.setEditor}
                             onClear={this.clearEditor}
                             speechInputNoFocus={true}
                             speechInputActive={this.state.textFieldSpeechInputActive}
                             speechInputAction={this.toggleTextFieldSpeechInput}
                             selectSpeechInputLang={this.selectTextInputLang}
                             selectedLang={this.state.lang}
                             autodetect={this.autodetectLang} label={buttonLabel}
                             icon={buttonIcon} action={buttonAction}
                             copy={copyAction} share={shareAction}
                             cancel={undefined} undo={!this.isKeyboardShowing() &&
                                                      this.canUndo() && this.undoEdit}
                             redo={!this.isKeyboardShowing() && this.canRedo() &&
                                   this.redoEdit}
                             onBlur={() => {
                               this.setTextInputFocus(false) }}
                             onFocus={() => {
                               this.setTextInputFocus(true) }}
                             onInput={this.onInput}
                             applyCompletion={this.applyCompletion}
                             completions={this.state.completions}
                             bottomRow={bottomRow}
                             
                             menu={menu}
                          />
                             
               {isDesktop() && !this.props.me.isAskAppOnly() && <div className='chatGptTooltip'>{decodeURIComponentExt(this.state.tooltip || '')}</div>}
             </div>
             {showKeyboard && <div className='chatGPTKeyboard'>
              <Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={() => this.setState({showKeyboard: false})} isWritingAssistant={true}/>
                              </div>}
             {showSearchField && <div className='chatGptSearchFieldContainer'>
                                   {this.renderSearchField()}
                                   
                                 </div>}
           </div>
  }

  setInputRef = ref => {
    if (ref != this.inputRef) {
      this.inputRef = ref
      if (ref) ref.observeEditorHeight().subscribe(height => {
        this.setState({
          editorHeight: height
        })
      })
    }
  }

  checkScrollBack2 = async () => {
    if (this.props.me.isHelpful()) {
      return await this.checkScrollBack()
    }
  }
  checkScrollBack = async () => {
    let earliest = Date.now()
    for (const k in this.received) {
      const { ts } = this.received[k]
      earliest = Math.min(ts, earliest)
    }
    let prev
    ////debugger
    if (this.props.me.isHelpful()) {
      prev = await this.props.getTaskHistory(this.state.selectedTask.id, earliest, 10)
    } else {
      prev = await this.props.getHistory(this.state.selectedModel, earliest, 10)
    }
    //////debugger
    for (const msg of prev) {
      this.parseMessage(msg)
      this.received[msg.id] = msg
    }
    this.forceUpdate()
  }

  setSearchEditor = editor => {
    if (this.searchEditorSub) {
      this.searchEditorSub.unsubscribe()
      this.searchEditorSub = null
    }
    this.searchEditor = editor
    if (editor) {
      this.searchEditorSub = editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          searchCanApply: !isEmpty
        })
      })
    }
    this.forceUpdate()
  }

  setEditor = ref => {
    if (this.editorSub) {
      this.editorSub.unsubscribe()
    }
    if (ref) {
      this.state.editorCanApply = true
      this.editor = ref
      this.editorSub = this.editor.observeIsEmpty().subscribe(isEmpty => {
        this.setState({
          editorCanApply: !isEmpty 
        })
      })
    }
    this.forceUpdate()
  }
  
  clearEditor = () => {
    this.state.completions = []
    this.renderCurrentDocument()
  }

  isKeyboardShowing = () => !isDesktop() && this.state.textInputFocus

  undoEdit = async () => {
    ////////debugger
    if (this.canUndoCurrentDocument()) {
      const doc = this.getCurrentDocument()
      doc.undo()
    } else {
      let i = this.getCurrentInstruction()
      this.state.variableToComplete = null
      if (i) {
        if (i.output) {
          this.state.edits.undo()
          i.undoOutput = i.output
          i.output = null
        } else if (i.inputText) {
          i.inputTemplate = null
          i.variableToComplete = null
          i.instruction = new Document(i.inputText)
          i.inputText = null
        } else {
          this.state.instructions.undo()
          i = this.getCurrentInstruction()
        }
        this.buildNextInstruction(i)
        this.renderCurrentDocument()
        return
      }
      if (this.state.instructions.canUndo()) {
        this.state.instructions.undo()
        const i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      } else {
        this.buildNextInstruction()
      }
    }
    this.renderCurrentDocument()
  }
  
  redoEdit = async () => {
    ////////debugger
    const doc = this.getCurrentDocument()
    if (doc && doc.canRedo()) {
      doc.redo()
    } else {
      let i = this.getCurrentInstruction()
      if (i) {
        if (i.undoOutput) {
          i.output = i.undoOutput
          i.undoOutput = null
          this.state.edits.redo()
          this.buildNextInstruction()
        } else {
          this.state.instructions.redo()
          i = this.getCurrentInstruction()
          this.buildNextInstruction(i)
        }
      } else if (this.state.instructions.canRedo()) {
        this.state.instructions.redo()
        i = this.getCurrentInstruction()
        this.buildNextInstruction(i)
      }
    }
    this.renderCurrentDocument()
  }

  showKeyboard = () => {
    if (true) return
    if (false) {
      const back = () => {
        this.setState({
          subpage: null,
          popup: null
        })
      }
      this.setState({
        popup: () => <div className='chatGPTKeyboard'><Keyboard me={this.props.me} sendKeyboardOutput={this.sendKeyboardOutput} cancelKeyboardOutput={back} isWritingAssistant={true}/></div>
      })
    } else {
      this.setState({
        showKeyboard: true
      })
    }
  }
    
  setTextInputFocus = textInputFocus => {
    this.showKeyboard()
    let instructionFocus = this.state.instructionFocus
    if (textInputFocus) {
      instructionFocus = false
    } else {
      this.onBlur()
    }
    this.setState({
      textInputFocus,
      instructionFocus
    },() => {
      this.updateLang()
      if (this.editor.focused) {
        this.onInput()
      }
    })
    if (!instructionFocus && !textInputFocus) {
      //this.stopVoiceInput()
    }
  }
  renderCurrentDocument = () => {
    const text = this.renderText(this.getCurrentDocument())
    if (this.editor.setText(text)) {
      this.focusedText = undefined
    }
    this.forceUpdate(this.showLastInstruction)
    return text
  }

  copyToClipboard = async text => {
    navigator.clipboard.writeText(text)
    await delay(0.5)
  }

  copy = async () => {
    const text = this.editor.getText()
    return this.copyToClipboard(text)
  }

  renderText = (edit) => {
    return (edit && edit.getCurrent()) || ''
  }

  getCurrentDocument = () => {
    return this.state.edits.getCurrent()
  }

  cancel = () => {
    this.props.back()
  }

  goBack = () => {
    this.setState({
      active: false
    })
    this.props.back()
  }

  canUndo = () => {
  }

  canRedo = () => {
  }


  updateLang = () => {
  }

  inputSeq = 0
  onInput = async e => {
    //debugLog("onInput", e)
    const seq = ++this.inputSeq
    if (this.state.completions.length > 0) {
      this.state.completions = []
      this.forceUpdate()
    }
    this.setState({
      questionSearchTerm: this.editor.getText()
    })
  }

  clearError = () => {
  }

  onBlur = e => {
    this.state.editing = false
    this.state.completions = []
    const text = this.editor.getText()
    if (this.getCurrentText() == text) {
      //debugLog("onBlur no change")
      this.forceUpdate()
      return 
    }
    //debugLog("onBlur text changed")
    this.clearError()
    const doc = this.getCurrentDocument()
    //////////debugger
    this.forceUpdate()
  }

  getCurrentText = () => {
    const doc = this.getCurrentDocument()
    return doc ? doc.getCurrent() || "" : ""
  }

  getCurrentTopic = () => {
    let topic
    if (this.state.selectedThread) {
      topic = this.state.selectedThread.id 
    } else {
      let ts = 0
      let lastMessage
      for (const id in this.received) {
        const message = this.received[id]
        if (message.ts > ts) {
          ts = message.ts
          lastMessage = message
        }
      }
      if (lastMessage) {
        topic = lastMessage.topic
      }
    }
    if (!topic) {
      if (this.state.selectedTask) {
        topic = this.state.selectedTask.lastTopic.id
      }
    }
    return topic
  }

  waitForUploads = () => {
    return new Promise((resolve, reject) => {
      const checkForUploadsDone = () => {
        if (this.state.uploads.length === 0) {
          resolve()
        } else {
          setTimeout(checkForUploadsDone, 500)
        }
      }
      checkForUploadsDone()
    })
  }

  sendChat = async () => {
    await this.waitForUploads()
    //////debugger
    const html = this.editor.getHTML()
    /*
    let content  = []
    let result = ''
    const flush = () => {
      if (result) {
        result = result.replace(/\n•[ ]+/g, "\n•  ");
        content.push({
          type: "text",
          text: result
        })
        result = ''
      }
    }
    const apply = node => {
      walkDOM(node, n => {
        if (n instanceof HTMLVideoElement) {
        }
        else if (n instanceof HTMLImageElement) {
          const url = n.src
          flush()
          content.push({
            type: "image_url",
            image_url: {
              url
            }
          })
        } else if (n.nodeName == "WBR") {
        } else if (n.nodeName == "BR") {
          result += "\n";
        } else if (n.nodeType == 3 && !(n.parentElement instanceof HTMLSpanElement)) {
          let textContent = n.textContent.replace(nbsp, " ");
          result += textContent;
          if (n.parentElement !== node && n.parentElement instanceof HTMLDivElement) {
            result += '\n'
          }
        }
      })
    }
    apply(this.editor.getNode())
    let text = this.editor.getText().trim()
    */
    let text = turndownService.turndown(html)
    if (text || content.length > 0) {
      if (this.state.selectedThread) {
        this.messages2.scrollToBottom()
      } else {
        this.messages1.scrollToBottom()
      }
      let topic = this.getCurrentTopic()
      let isNewTopic = false
      if (topic === 'new-thread') {
        topic = undefined
        isNewTopic = true
      }
      let task = this.state.selectedTask
      if (this.props.me.isHelpful() && !this.hasTasks()) {
        // this is the user's first task
        task = this.props.me.createNewTask()
        this.selectThread(task)
        isNewTopic = true
      }
      const sent = Date.now()
      const msg = {
        id: 'pending',
        text,
        content: text,
        ts: Date.now(),
        from: this.props.me.self.uid,
        sent,
        model: this.state.selectedModel
      }
      this.received['pending'] = msg
      msg.topic = topic
      if (task && task.id !== 'new-task') {
        msg.task = task.id
      }
      if (!this.props.me.isHelpful()) {
        if (this.state.selectedThread) {
          this.state.searchResultsBusy = true
          this.state.searchResults.push(msg)
        }
      }
      this.state.isStreaming = true
      this.forceUpdate()
      /*
      this.props.me.sendChat(msg, isNewTopic).then(result => {
        const {error} = result
        if (error) switch (error) {
          case 'not-enough-tokens':
          return this.notEnoughTokens()
        }
        })
      */
      let spoken = 0
      let completeText = ''
      const getMessage = () => {
        let found = msg
        for (const id in this.received) {
          const msg = this.received[id]
          if (msg.sent === sent) {
            const reply = this.wasAnswered(msg)
            if (reply) {
              found = reply
              if (msg.stream) {
                msg.stream = null
                delete this.cache[msg.id]
              }
            } else {
              found = msg
            }
            break
          }
        }
        return found
      }

      const getReplyMessage = () => {
        let found = getMessage()
        if (!this.state.selectedThread) {
          if (found.inReplyTo) {
            return found
          }
          return this.wasAnswered(found)
        } else {
          let id = found.inReplyTo || found.id
          return this.state.searchResults.find(x => x.inReplyTo === id)
        }
      }
      
      const flushToSpeaker = async (isFinal) => {
        if (this.recognition && isFinal) {
          const reply = getReplyMessage()
          consoleLog("GOT REPLY", reply)
          this.state.speaking = reply.id
          let lang = this.state.lang.iso
          this.props.me.resetAudioSource()
          this.recognition.stop()
          this.forceUpdate()
          return this.props.me.speak(completeText, lang).then(() => {
            this.state.speaking = null
            this.forceUpdate()
            this.recognition.start()
          })
        }
      }
      this.streamSent = msg.sent
      this.xhr = await this.props.streamChat(msg, isNewTopic, {
        model: this.state.selectedModel,
        assistantModel: this.state.assistantModel,
        onContent: text => {
          const found = getMessage()
          if (found) {
            if (found.from == this.props.me.self.uid) {
              found.stream = text
            } else {
              found.text = text
            }
            completeText = text
            flushToSpeaker()
            delete this.cache[found.id]
            this.forceUpdate()
          } else {
            //////debugger
          }
        },
        onDone: () => {
          this.xhr = null
          flushToSpeaker(true).then(() => {
            this.state.isStreaming = false
            this.forceUpdate()
          })
        },
        onError: (err) => {
          this.state.isStreaming = false
          this.forceUpdate()
        }
      })
      this.clearEditor()
    }
    if (isMobile()) {
      this.editor.blur()
    }
  }

  pauseChat = async () => {
    const xhr = this.xhr
    const sent = this.streamSent
    consoleLog({xhr, sent})
    this.xhr = null
    this.streamSent = 0
    if (xhr) {
      xhr.abort()
      consoleLog("aborted xhr")
    }
  }

  notEnoughTokens = instruction => {
  }

  getThreads = () => {
    let threads
    if (this.props.me.isHelpful()) {
      if (this.state.slide >= 0.5) {
        return []
      }
      threads = Object.values(this.tasks).filter(t => t.topic)
      if (this.state.searchResults) {
        threads = this.state.searchResults
      }
      ////debugger
    } else {
      threads = Object.values(this.chatThreads).filter(t => t.topic)
      if (this.state.searchResults && !this.state.selectedThread) {
        threads = threads.filter(x => this.state.searchResults.find(y => y.topic == x.id))
      }
    }
    threads.sort((x, y) => {
      return y.lastUpdated - x.lastUpdated
    })
    return threads
  }

  setAutocomplete = ref => {
    this.autocomplete = ref
    if (this.inputRef && ref) {
      ref.setInput(this.inputRef)
    }
  }

  selectThread = threadOrTask => {
    let thread
    let task
    ////debugger
    if (this.props.me.isHelpful()) {
      task = threadOrTask
      if (task && this.state.selectedTask && this.state.selectedTask.id === task.id) return
      localStorage.setItem('selectedTaskId', task ? task.id : '')
    } else {
      thread = threadOrTask
      if (thread && this.state.selectedThread && this.state.selectedThread.id === thread.id) return
    }
    clearTimeout(this.timeout)
    clearInterval(this.interval)
    const target = threadOrTask ? 1.0 : 0.0
    const left = this.sliderContainer.scrollLeft
    const fraction = left / window.innerWidth
    debugLog("LEFT", left)
    const dur = 600
    const start = Date.now() - fraction * dur
    let delay = 0
    this.animating = true
    this.timeout = setTimeout(() => {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        window.requestAnimationFrame(() => {
           const elapsed = (Date.now() - start)
          let t = Math.min(elapsed / dur, 1.0)
          t = 1 - Math.pow(1 - t, 7)
          const updates = {
            slide: target ? t : 1.0 - t
          }
          let f
          if (t >= 1) {
            this.animating = false
            clearInterval(this.interval)
            if (!threadOrTask) {
              this.state.selectedThreads.pop()
              updates.selectedThread = this.state.selectedThreads[this.state.selectedThreads.length]
              updates.selectedTask = null
              updates.threadBusy = false
            }
            f = this.performSearch
          }
          this.setState(updates, f)
        })
      }, 1000/60)
    }, delay)
    if (thread) {
      this.state.selectedThreads.push(thread)
      this.setState({
        threadBusy: true,
        selectedThread: thread
      }, this.performSearch)
    } else if (task) {
      let { lastTopic } = task
      if (lastTopic) {
        thread = {
          id: lastTopic.topicId,
          topic: lastTopic.topic,
          lastUpdated: task.lastUpdated
        }
      } else {
        ////debugger
      }
      this.setState({
        threadBusy: true,
        selectedTask: task,
        selectedThread: thread
      },this.initSelectedTask)
    } else {
      this.editor.clear()
    }
  }

  seqNum = 0
  search = async searchTerm => {
    this.state.searchTerm = searchTerm.trim()
    this.forceUpdate()
    this.performSearch()
  }

  parseMessage = message => {
    if (!message.topic) {
      if (message.inReplyTo) {
        const src = this.received[message.replyTo]
        if (src) {
          message.topic = src.topic
        }
      }
    }
    let text = message.text || ''
    if (text) {
      const code = parseCode(text)
      if (code) {
        message.code = code
      } else {
        const table = parseTable(text)
        if (table) {
          message.table = table
        }
      }
    } else{
      message.text = ''
    }
  }

  performSearch = async () => {
    const seq = ++this.seqNum
    const searchTerm = this.state.searchTerm
    let topic = this.state.selectedThread ? this.state.selectedThread.id : ''
    if (searchTerm || topic) {
      this.state.searching = true
      if (!this.state.searchTerm) {
        this.state.threadBusy = true
      }
      this.forceUpdate()
      let { results, page, out_of }  = await this.props.searchChatMessages(searchTerm, topic, this.state.selectedModel, this.state.selectedTask ? this.state.selectedTask.id : '*')
      console.log("SEARCH", seq, this.seqNum, results)
      results.forEach(message => {
        this.parseMessage(message)
      })
      if (seq === this.seqNum) {
        if (topic && !searchTerm) {
          const byId = {}
          results.forEach(x => {
            byId[x.id] = x
          })
          const messages = this.getMessages()
          for (const message of messages) {
            if (message.id !== 'pending' && message.topic === topic && !byId[message.id]) {
              results.push(message)
            }
          }
        }
        if (this.props.me.isHelpful()) {
          let seen = {}
          results = results.filter(result => {
            let keep = result.task && !seen[result.task]
            seen[result.task] = true
            return keep
          })
          results = results.map(x => this.tasks[x.task])
        }
        this.setState({
          searching: false,
          threadBusy: false,
          searchResults: results
        })
      }
    } else {
      this.setState({
        searching: false,
        searchResults: null,
        threadBusy: false
      })
    }
  }

  onKeyDown = e => {
    const RETURN = "Enter";
    if (isDesktop()) {
      if (e.key === RETURN && !e.shiftKey) {
        e.preventDefault()
        this.sendChat()
      }
    }
  }


  renderSearchField = () => {
    let busy = this.state.searching && this.state.searchTerm
    let searchTerm = ''
    const clear = () => {
      // fixme!!
      this.searchEditor.clear()
      this.search('')
    }
    const onFocus = () => {
    }
    const onBlur = () => {
    }
    const onInput = () => {
      this.search(this.searchEditor.getText())
    }
    const selectThread = thread => {
      this.selectThread(thread)
    }
    let icon
    let label
    let action
    action = async () => {
      if (this.props.me.isHelpful()) {
        ////debugger
        if (this.state.selectedTask) {
          const task = this.state.selectedTask
          if (!this.currentTaskHasMessages()) {
            delete this.tasks[task.id] // make sure its not visible
            this.deleteTask(task)
          }
          this.props.me.updateTaskSummary(task)
          this.selectThread(null)
        } else {
          const task = this.props.me.createNewTask()
          this.selectThread(task)
        }
      } else {
        if (this.state.selectedThread) {
          this.selectThread(null)
        } else {
          return this.selectThread({
            id: 'new-thread',
            topic: 'New Topic'
          })
        }
      }
    }
    if (this.state.slide < 0.5) {
      label = 'New'
      icon = Hashtag
    } else {
      label = 'Back'
      icon = Left
    }
    const newTopicButton = <KeyboardButton className={'newTopicButton'} icon={icon} action={action} label={label}/>
    const selectedThread = this.state.selectedThread
    return <div key='nextInstruction' className='keyboardInstructionInput'>
             <InputControl
               busy={busy}
               middleLeft={newTopicButton}
               me={this.props.me}
               placeholder={'Search'}
               onSetEditor={this.setSearchEditor}
               downward={true}
               onClear={clear}
               onFocus={onFocus}
               onBlur={onBlur}
               onInput={onInput}
               menu={this.state.active && <Threads me={this.props.me}
                                                   checkScrollBack={this.getThreadsHistory} slide={this.state.slide} selectThread={selectThread} selectedThread={selectedThread} threads={this.getThreads()} deleteTask={this.deleteTask}/>}                   
             />
             </div>
  }


  deleteTask = async task => {
    await this.props.me.deleteTask(task)
    delete this.tasks[task.id]
    this.forceUpdate()
  }


  getThreadsHistory = async () => {
    if ((this.props.me.isHelpful() || !this.state.selectedThread) && !this.state.searchResults) {
      let earliest = Date.now()
      for (const k in this.chatThreads) {
        const t = this.chatThreads[k]
        earliest = Math.min(t.lastUpdated, earliest)
      }
      const prev = await this.props.getThreadsHistory(this.state.selectedModel, earliest, 30)
      for (const thread of prev) {
        this.chatThreads[thread.id] = thread
      }
      this.forceUpdate()
    }
  }

  toggleTextFieldSpeechInput = async () => {
    const textFieldSpeechInputActive = !this.state.textFieldSpeechInputActive
    if (this.state.voiceRecognitionActive) {
      this.toggleVoiceRecognition()
    }
    this.setState({
      textFieldSpeechInputActive,
      instructionSpeechInputActive: false
    }, () => {
      if (textFieldSpeechInputActive) {
        this.toggleVoiceRecognition()
      }
    })
  }
  
  toggleVoiceRecognition = async e => {
    if (e) e.preventDefault()
    //////////debugger
    this.state.voiceRecognitionActive = !this.state.voiceRecognitionActive
    if (this.recognition) {
      this.recognition.stop()
      this.recognition = null
    }
    if (!this.state.voiceRecognitionActive) {
      this.setState({
        instruction: null
      })
      if (this.sub3) {
        this.sub3.unsubscribe()
        this.sub3 = null
      }
      if (this.sub4) {
        this.sub4.unsubscribe()
        this.sub4 = null
      }
    } else {
      this.recognition = this.props.me.getVoiceRecognizer()
      this.sub3 = this.recognition.observeIsActive().subscribe(isActive => {
        if (this.state.voiceRecognitionActive !== isActive) {
          console.log("isActive", isActive)
          this.state.voiceRecognitionActive = isActive
          this.forceUpdate()
        }
      })
      this.sub4 = this.recognition.observeInstruction().subscribe(instruction => {
        console.log("instruction", instruction)
        this.receiveVoiceInput(instruction)
      })
      this.updateLang()
      this.recognition.start()
    }
    this.forceUpdate(this.updateLang)
  }

  selectTextInputLang = lang => {
    localStorage.setItem('keyboard.text.lang', JSON.stringify(lang))
    this.setState({
      lang
    }, this.updateLang)
  }
  
  updateLang = () => {
    if (this.state.instructionSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.instructionLang.iso)
      }
    }
    else if (this.state.textFieldSpeechInputActive) {
      if (this.recognition) {
        this.recognition.setLang(this.state.lang.iso)
      }
    } else {

    }
  }

  speakText = async (cancel) => {
    if (cancel) {
      this.props.me.cancelSpeak()
    } else {
      const text = this.editor.getText()
      this.props.me.resetAudioSource()
      await this.props.me.speak(text, this.state.lang.iso)
    }
  }

  receiveVoiceInput = async input => {
    consoleLog("voice input", input)
    if (this.state.instructionSpeechInputActive) {
      const { instruction } = this.state.nextInstruction
      const editor = this.instructionEditor
      editor.insertTextAtCaret(input)
      const text = editor.getText()
      instruction.advance(text)
      const { isComplete, corrected } = await this.props.me.autocorrect({input: text, lang: this.state.instructionLang.iso})
      if (corrected && text != corrected) {
        instruction.undo()
        instruction.advance(corrected)
        editor.setText(corrected)
      }
    } else if (this.state.textFieldSpeechInputActive) {
      this.editor.insertTextAtCaret(input)
      const text = this.editor.getText()
      const data  = await this.props.me.autocorrect({input: text, lang: this.state.lang.iso})
      const { isComplete, corrected } = data
      if (corrected && text != corrected) {
        this.editor.setText(corrected)
      }
      if (isComplete && !this.state.isStreaming) {
        this.sendChat()
      }
    } else {
      console.error("voice input fail")
    }
    this.forceUpdate()
  }

  factCheck = async (id) => {
    return await this.props.me.factCheck(id)
  }

  uploadFile = async file => {
    const upload = {
      file: file,
      progress: 0,
      blobUrl: URL.createObjectURL(file)
    };
    const progress = percent => {
      upload.progress = percent;
      this.forceUpdate();
    }
    this.state.uploads.push(upload)
    this.forceUpdate()
    let img
    if (file.type && file.type.startsWith("image/")) {
      const url = URL.createObjectURL(file);
      img = this.editor.insertImage(url)
    }
    const name = file.name.toLowerCase();
    debugger
    if (name.endsWith(".mov")) {
      try {
        file = new File(file, name.replace(".mov", ".mp4"));
      } catch (err) {
        this.props.me.nativeLog(err)
      }
    }
    try {
      const ref = await this.props.me.uploadFile(file, progress, false)
      if (img) {
        debugger
        img.src = await ref.getDownloadURL()
        this.forceUpdate()
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({
        uploads: this.state.uploads.filter(x => x.file != file),
      })
    }
  }

  handleDataTransfer = (event, transfer)=> {
    if (transfer.files.length > 0) {
      event.preventDefault();
      for (const file of transfer.files) {
        this.uploadFile(file);
      }
      return true;
    }
    return false;
  }
  
  onPaste = e => {
    if (e.clipboardData.files && e.clipboardData.files.length > 0) {
      if (this.handleDataTransfer(e, e.clipboardData)) {
        return;
      }
    }
  }
  
  onUpdate = e => {
  }

  onDrop = e => {
    const transfer = e.dataTransfer;
    this.handleDataTransfer(e, transfer);
  }

}

