import React from 'react'
import Markdown1 from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import remarkGfm from 'remark-gfm'

export const Markdown = props => {

  return <Markdown1
           components={props.components}
           remarkPlugins={[remarkMath, remarkGfm]}
           rehypePlugins={[rehypeKatex]}>
           {props.children}
         </Markdown1>
}
 
