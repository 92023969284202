import React, { Component } from 'react'
import { ReactSVG } from 'react-svg'
import { isMobile, isDesktop } from '../../classes/Platform.js'
import { BnLabel1, BnLabel2 } from '../Label'
import { BnPage, BnSubpage } from '../Page'
import { BnForm, BnFormFields, BnFormFieldSeparator as Sep } from '../Form'
import { parsePhoneNumber, BnInputField, BnInputFieldSeparator } from '../TextInput'
import { KeyboardButton, KeyboardButton1 } from '../Keyboard'
import { delay, capitalize } from '../../classes/Util.js'
import { Keyboard } from '../Keyboard'
import { ChatGPT } from '../ChatGPT'
import { GetStarted, KeyboardLogin } from '../KeyboardLogin'
import Trash from '../../assets/Icons/Trash.svg'
import Twitter from '../../assets/Icons/Twitter.svg'
import Facebook from '../../assets/Icons/FBook.svg'
import LinkedIn from '../../assets/Icons/LnkIn.svg'
import TikTok from '../../assets/Icons/TikTok.svg'
import Copy from '../../assets/Icons/Copy.svg'
import Share from '../../assets/Icons/Share.svg'
import UserSaid from '../../assets/Icons/UserSaid.svg'
import Pen from '../../assets/Icons/Edited.svg'
import Create from '../../assets/Icons/Create.svg'
import Edit from '../../assets/Icons/SavedCommands.svg'
import Left from '../../assets/Icons/Back.svg'
import Right from '../../assets/Icons/Forward.svg'
import Pack from '../../assets/Icons/Pack.svg'
import Buy from '../../assets/Icons/Buy.svg'
import Profile from '../../assets/Icons/Profile.svg'
import Spin from '../../assets/Icons/Spin.svg'
import AISaid from '../../assets/Icons/AISaid.svg'
import HomeNewUser from '../../assets/Icons/Guide0012x.png'
import Cross from '../../assets/Icons/Cross_1.svg'
import Update from '../../assets/Icons/Update.svg'
import HeroImage from '../../assets/Icons/HeroImage.png'
import ClickAwayListener from 'react-click-away-listener'
import { WordPackPurchase } from '../Words'
import phone from 'phone';
import './index.css'

const TITLE = 'IntelliKey'

const SavedButtonTabs = props => {
  const { tabs, selection, select } = props
  return <div className='savedButtonTabs'>
           {
             tabs.map(tab => {
               const selected = tab.selector === selection
               const onClick = () => {
                 select(tab.selector)
               }
               return <SavedButtonTab icon={tab.icon} label={tab.label} onClick={onClick} selected={selected}/>
             })
           }
         </div>
}

const SavedButtonTab = props => {
  const { selected, icon, label, onClick } = props
  let className = 'savedButtonTab'
  if (selected) {
    className += ' savedButtonTabSelected'
  }
  return <div className={className} onClick={onClick}>
           <div className='savedButtonTabIcon'>
             <ReactSVG src={icon}/>
           </div>
           <div className='savedButtonTabLabel'>
             {label}
           </div>
         </div>
}


class SavedButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }
  }
  render() {
    const { button } = this.props
    const { id, instruction, category } = button
    const { busy } = this.state
    const deleteButton = async () => {
      await this.props.me.deleteButton(button)
    }
    const shareButton = async () => {
      debugger
      await this.props.me.shareButton(button)
    }
    return <div className='savedButton'>
             <div className='savedButtonLeft'>
               <div className='savedButtonIcon'>
                 <ReactSVG src={UserSaid}/>
               </div>
               <div className='savedButtonLabel'>
                 {instruction}
               </div>
             </div>
             <div className='savedButtonRight'>
               <KeyboardButton1 className='savedButtonDelete' icon={Trash} action={deleteButton}/>
               <KeyboardButton1 className='savedButtonShare'icon={Share} action={shareButton}/>
               </div>
           </div>
  }
}

class SavedButtonCategory extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }
  renderButtons() {
    return this.props.buttons.map(button => {
      return <SavedButton me={this.props.me} button={button}/>
    })
  }
  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    })
  }
  render() {
    const { open } = this.state
    return <div className={'savedButtonCategory' + (open ? ' savedButtonCategoryOpen' : '')}>
             <div className='savedButtonCategoryMenu' onClick={this.toggleMenu}>
               <div className='savedButtonIcon'>
                 <ReactSVG src={open ? Left : Right}/>
                 </div>
               <div className='savedButtonLabel'>
                 {this.props.category}
               </div>
             </div>
             {open && <div className='savedButtonCategoryButtons'>
                         {this.renderButtons()}
                       </div>}
           </div>
  }
}

export const HomeInput = props => {
  const { form, formErr, onChange, name, type, autocomplete, label, sublabel} = props
  let className = 'homeButton'
  if (props.className) className += ' ' + props.className
  return <div className={className}>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
             <div className='homeButtonSublabel'>{sublabel}</div>
           </div>
           <BnInputField name={name} label={''} formErr={formErr} form={form} type={type} onChange={onChange} autoComplete={autocomplete} busy={form.busy}/>
         </div>
}

const HomeLabel = props => {
  const { label, content } = props
  return <div className='homeButton'>
           <div className='homeButtonLabels'>
             <div className='homeButtonLabel'>{label}</div>
           </div>
           {content}
         </div>
  }


export class HomeButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      busy: false
    }    
  }

  onClick = async () => {
    if (this.state.busy) return
    this.state.busy = true
    this.forceUpdate()
    if (this.props.action) await this.props.action()
    this.state.busy = false
    this.forceUpdate()
  }
  
  render() {
    const props = this.props
    const busy = this.props.busy || this.state.busy
    const  right = (busy) ? <ReactSVG src={Spin}/> : props.right
    let className = 'homeButton'
    if (props.className) {
      className += ' ' + props.className
    }
    return <div className={className}>
             <div className='homeButtonLabels'>
               <div className='homeButtonLabel'>{props.label}</div>
               <div className='homeButtonSublabel'>{props.sublabel}</div>
             </div>
             <div className='homeButtonButton' onClick={this.onClick}>
               <div className='homeButtonButtonIcon'>
                 <ReactSVG src={props.icon}/>
               </div>
               <div className='homeButtonButtonLabel'>
                 {props.buttonLabel}
               </div>
             </div>
             <div className={'homeButtonRight' + (busy ? ' homeButtonRightBusy' : '')}>
               {right}
             </div>
           </div>
  }
}

const formatAmount = amount => {
  if (amount > 1000000) {
    return Math.round(amount / 1000000).toFixed(1) + 'M'
  }
  if (amount > 1000) {
    return Math.round(amount/1000) + 'K'
  }
  return amount
}

const Stat = props => {
  let className = 'keyboardHomeStat'
  if (props.className) {
    className += ' ' + props.className
  }
  return <div className={className}>
           <div className='keyboardHomeStatLabel'>
             {props.label}
             </div>
           <div className='keyboardHomeStatValue'>
             {formatAmount(props.value)}
             </div>
           </div>
}


class Checkbox extends Component {
  render() {
    let button
    if (this.props.selected) {
      button = <div className='keyboardCheckboxSelected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxLeft'/>
                 <div className='keyboardCheckboxOn'>On</div>
               </div>
      
    } else {
      button = <div className='keyboardCheckboxUnselected' onClick={this.props.toggle}>
                 <div className='keyboardCheckboxOff'>Off</div>
                 <div className='keyboardCheckboxOffButton'/>
               </div>
      
    }
    return <div className='keyboardCheckbox'>
             <div className='keyboardCheckboxLabel'>
               {this.props.label}
             </div>
             {button}
             <div className='keyboardCheckboxRight'/>
           </div>

  }
}

export class Account extends BnSubpage {

  componentDidMount() {
    if (this.props.me.isAskAppOnly()) {
      this.props.me.getApiKey().then(apiKey => {
        this.set('apiKey', apiKey || '')
        this.forceUpdate()
      })
    } else {
      this.props.me.getReferralCode().then(code => {
        this.set('referralCode', code)
        this.forceUpdate()
      })
    }
    const { countryCode, phoneNumber } = parsePhoneNumber(this.props.me.self.phoneNumber)
    this.set('countryCode', countryCode)
    this.set('phoneNumber', this.props.me.self.phoneNumber)
    this.set('apiUrl', 'https://functions-o4pon4eraa-uc.a.run.app/api/v1/chat/completions')
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  updatePhoneNumber = async () => {
    const form = this.getForm()
    let phoneNumber = form.phoneNumber
    let countryCode = form.countryCode
    const converted = phone(phoneNumber);
    if (!converted.length) {
      formErr = {field: 'phoneNumber', message: 'Invalid phone number.'};
      this.forceUpdate()
      return
    } else {
      phoneNumber = converted[0];
    }
    await this.props.me.updatePhoneNumber(phoneNumber)
  }

  signOut = async () => {
    await this.props.me.signOut()
  }

  signUp = async () => {
    debugger
    const back = this.back
    this.setState({
      subpage: () => <KeyboardLogin me={this.props.me} safeArea={true} back={back}/>
    })
  }

  deleteAccount = async () => {
    if (!this.state.confirmDeleteAccount) {
      this.setState({
        confirmDeleteAccount: true
      })
    } else {
      await this.props.me.deleteAccount()
    }
  }

  cancelDeleteAccount = () => {
    this.setState({
      confirmDeleteAccount: false
    })
  }

  copyField = async (field) => {
    const form = this.getForm()
    navigator.clipboard.writeText(form[field])
    await delay(0.5)
  }

  renderContent() {
    let deleteAccountClass = 'keyboardHomeAccountDelete'
    let deleteAccountLabel = 'Delete Account'
    if (this.state.confirmDeleteAccount) {
      deleteAccountClass += ' keyboardHomeAccountDeleteConfirm'
      deleteAccountLabel = 'Confirm Delete Account'
    }
    const hasPhone = !this.props.me.isSignedInAnonymously()
    return <div className='bnSubpageContent keyboardHome'>
             <div className='keyboardHomeAccountSpacer0'/>
             {hasPhone && <div className='keyboardHomePhone'>
               <HomeInput type='tel' autocomplete='tel' name='phoneNumber' label='phone number' name='phoneNumber' form={this.getForm()} formErr={this.getFormErr()} onChange={undefined /*this.onChange*/}/>
                           {false && <KeyboardButton label='Update' icon={Update} action={this.updatePhoneNumber}/>}
                         </div>
             }
             {hasPhone && <div className='keyboardHomeAccountSpacer1'/>}
             {this.props.me.isAskAppOnly() ?
              [<div className='keyboardHomePhone'>
                 <HomeInput label='Non-hallucinating GPT-3.5-turbo' form={this.getForm()} name='apiUrl' className='inputReferralCode' sublabel='OpenAI Compatible API'/>
                 <KeyboardButton label='Copy' icon={Copy} action={()=>this.copyField('apiUrl')}/>
               </div>,
               <div className='keyboardHomePhone'>
                 <HomeInput label='your api key' form={this.getForm()} name='apiKey' className='inputReferralCode' sublabel='Use this api key to access the Attunewise.ai API'/>
                 <KeyboardButton label='Copy' icon={Copy} action={()=>this.copyField('apiKey')}/>
              </div>
               ]
              :
              <div className='keyboardHomePhone'>
              <HomeInput label='your referral code' form={this.getForm()} name='referralCode' className='inputReferralCode' sublabel='Share this referral code to get free word packs'/>
                <KeyboardButton label='Copy' icon={Copy} action={this.copyField('referallCode')}/>
              </div>              
             }
             <div className='keyboardHomeAccountSpacer1'/>
             {!this.props.me.isBaseModel() && !this.props.me.isAskAppOnly() && !this.props.me.isHelpful() && <div className='accountKeyboardSettings'>
               <div className='homeButtonLabel'>Keyboard settings</div>
               <div className='accountKeyboardSettingsCheckboxes'>
                 <Checkbox label={'Auto-Correction'} selected={this.props.getSetting('autoCorrection')}
                           toggle={()=>this.props.toggleSetting('autoCorrection')}/>
                 <Checkbox label={'Haptic Feedback'} selected={this.props.getSetting('hapticFeedback')}
                           toggle={()=>this.props.toggleSetting('hapticFeedback')}/>
                 <Checkbox label={'Audio Feedback'} selected={this.props.getSetting('audioFeedback')}
                           toggle={()=>this.props.toggleSetting('audioFeedback')}/>
               </div>
                                               </div>}

             <div className='keyboardHomeAccountSpacer1'/>
             {(this.props.me.isAskAppOnly() && this.props.me.isSignedInAnonymously()) ?
              <HomeButton label='sign up with phone number' buttonLabel='Sign up' action={this.signUp} icon={Profile}/>
              :
              <HomeButton label='sign out of your account' buttonLabel='Sign out' action={this.signOut} icon={Profile}/>
             }
             {hasPhone && <div className='keyboardHomeAccountSpacer2'/>}
             {hasPhone &&
              <ClickAwayListener onClickAway={this.cancelDeleteAccount}>
                <div className={deleteAccountClass}>
                  <HomeButton
                    icon={Cross} label='permanently close account'
                    sublabel='All data will be erased from our servers and you will need to create a new account to continue using IntelliKey'
                    buttonLabel={deleteAccountLabel} action={this.deleteAccount}/>
                </div>
                </ClickAwayListener>}
             </div>
  }
}


export class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      wordsUsed: 0,
      wordsPurchased: 0,
      buttons: [],
      writeButtons: [],
      savedButtonSelector: 'create'
    }
  }

  render() {
    const subpage = this.state.subpage ? this.state.subpage() : null
    const content = this.renderContent()
    return  <BnPage me={this.props.me} subpage={subpage} safeArea={true}>
             {content}
             </BnPage>
           
  }

  back = () => {
    this.setState({
      subpage: null
    })
  }

  account = async () => {
    this.setState({
      subpage: () => <Account title={"Account"}
                              me={this.props.me}
                              getSetting={this.props.getSetting}
                              toggleSetting={this.props.toggleSetting}
                              back={this.back}/>
    })
  }


  buy = async () => {
    if (this.props.me.isSignedInAnonymously()) {
      this.signUp()
      return
    }
    this.setState({
      subpage: () => <WordPackPurchase title={"Word Packs"}
                                       getSetting={this.props.getSetting}
                                       toggleSetting={this.props.toggleSetting}
                                       me={this.props.me} back={this.back} available={formatAmount(Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0))}/>
    })
  }

  shareKeyboardOutput = async output => {
  }

  openWritingAssistant = () => {
    this.setState({
      subpage: () => <Keyboard me={this.props.me}
                               onNotENoughTokens={this.onNotEnoughTokens}
                               isWritingAssistant={true}
                               cancelKeyboardOutput={this.back}
                               sendKeyboardOutput={this.shareKeyboardOutput}
                     />

    })
  }

  openModels = () => {
    this.openChat()
  }

  openBaseModels = () => {
    this.openChat({
      isBaseModel: true
    })
  }

  openChat = (opts) => {
    this.setState({
      subpage: () => <ChatGPT me={this.props.me} back={this.back}
                              isOpenAI={opts && opts.isOpenAI}
                              isBaseModel={opts && opts.isBaseModel || this.props.me.isBaseModel()}
                              observeChatMessages={this.props.me.observeChatMessages}
                              observeTaskMessages={this.props.me.observeTaskMessages}
                              observeChatThreads={this.props.me.observeChatThreads}
                              observeTasks={this.props.me.observeTasks}
                              deleteChatMessage={this.props.me.deleteChatMessage}
                              getHistory={this.props.me.getHistory}
                              getTaskHistory={this.props.me.getTaskHistory}
                              getThreadsHistory={this.props.me.getThreadsHistory}
                              searchChatMessages={this.props.me.searchChatMessages}
                              streamChat={this.props.me.streamChat}
                     />
    })
  }

  renderContent() {
    let openLinkedIn, openTwitter, openFacebook, openTikTok
    if (this.props.me.isBaseModel() || this.props.me.isAskAppOnly()) {
      openTwitter = () => {
        this.props.me.openWindow('https://twitter.com/attunewise')
      }
    } else {
      openLinkedIn = () => {
        this.props.me.openWindow('https://www.linkedin.com/company/badnano/')
      }
      openTwitter = () => {
        this.props.me.openWindow('https://twitter.com/intellikey_ai')
      }
      openFacebook = () => {
        this.props.me.openWindow('https://facebook.com/groups/IntelliKey')
      }
      openTikTok = () => {
        this.props.me.openWindow('https://tiktok.com/@intellikey.ai')
      }
    }
    const s = (window.innerWidth - 20) / 240
    let style = {
      transform: `scale(${s},${s})`,
    }
    let available = Math.max(this.state.wordsPurchased - this.state.wordsUsed, 0)
    if (isNaN(available)) {
      debugger
    }
    let className = 'keyboardWindow keyboardHome'
    let isLow = available < 1000
    let isLowMessage
    if (available === 0) {
      isLowMessage = 'You\'re out of words! You can purchase additional Word Packs for your account below.'
    } else if (isLow) {
      isLowMessage = 'Words are running low! You can purchase additional Word Packs for your account below.'
    }
    if (isLowMessage) {
      className += ' keyboardHomeWordPacksLowStatus'
    }

    if (this.props.me.isAskAppOnly()) {
      isLowMessage = "This is an Attunewise steering technology demo. Choose a model below."
    }

    let openAIStatusClass = ''
    let openAIStatusDescription = ''
    let provider = 'All'
    if (this.state.openAIStatus) {
      let { indicator, description } = this.state.openAIStatus
      openAIStatusClass = ' openAIStatus' + capitalize(indicator) 
      openAIStatusDescription = description.replace(/(Partially|All) /g, '');
      if (openAIStatusDescription !== 'Systems Operational') {
        provider = 'OpenAI'
      }
    }

    let blurbStyle = {
      fontSize: (Math.min(window.innerWidth, 600)*0.5 / 240) * 16
    }

    let askLabel = 'reliable answers'
    let askButtonLabel = 'Ask'
    let title = TITLE
    if (this.props.me.isAskAppOnly()) {
      title = "Attunewise.ai"
    }
    if (this.props.me.isHelpful()) {
      title = "Assistant"
      askLabel = "Perform Tasks"
      askButtonLabel = "Assistant"
    }
    if (this.props.me.isBaseModel()) {
      title = this.state.selectedModel
      askLabel = "chat"
      askButtonLabel = "Davinci"
    }

    return <div className={className}>
             <div className='keyboardHomeHeader'>
               <div className='keyboardHeaderTitle'>{title}</div>
             </div>
             <div className='keyboardHomeTop'>
               <div className='keyboardHomeShape'>
<svg id="HeroMask" data-name="Hero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 558 203">
	<defs>
		<clipPath id="shape">
			<polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" />
			<polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" />			
		</clipPath>
	</defs>

  <image href={HeroImage} clipPath="url(#shape)" />
			
	<g className="heroMaskLeft">
	  <polygon points=".359 43.07 65.272 155.504 37.969 202.795 222.403 202.795 130.186 43.07 .359 43.07" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
	<g className="heroMaskMiddle">
	  <polygon points="394.23 154.515 471.333 21.328 281.753 21.328 293.949 .205 115.766 .205 204.858 154.515 394.23 154.515" style={{fill:"#086461;"}} opacity="0.9" />
	</g>
			
  <g className={"heroMaskRight" + openAIStatusClass}>
	  <polygon points="481.15 21.408 404.301 154.515 558 154.515 481.15 21.408" style={{fill:"#0A807E;"}} />
          <g transform='translate(481.15, 148.515)'>
                      <text className={'openAIStatusDescriptionOpenAI'}>{provider}</text>
                       <text className={'openAIStatusDescription'}>{openAIStatusDescription}</text>
                     </g>
	</g>
</svg>
                 <div className='keyboardHomeStats'>
                   <Stat className='statAvailable' label='words available' value={available}/>
                   <Stat className='statUsed' label='used' value={this.state.wordsUsed || 0}/>
                   <Stat className='statPurchased' label={this.state.nonFree ? 'purchased' : 'free'} value={this.state.wordsPurchased || 0}/>

                 </div>
               </div>
             </div>
             <div className='intellikeyLinks' style={null}>
               {openFacebook && <div className='facebookLink' onClick={openFacebook}><ReactSVG src={Facebook}/></div>}
               {openTwitter && <div className='facebookLink' onClick={openTwitter}><ReactSVG src={Twitter}/></div>}
               {openTikTok && <div className='facebookLink' onClick={openTikTok}><ReactSVG src={TikTok}/></div>}
             </div>
             <div className='keyboardHomeBottom'>
               <div className='keyboardHomeButtons'>
                 {isLowMessage && <div key='lowBro' className='keyboardHomeWordPacksLow'>
                                    <div className='keyboardHomeWordPacksLowIcon'>
                                    <ReactSVG src={AISaid}/>
                                    </div>
                                    <div className='keyboardHomeWordPacksLowMessage'>
                                      {isLowMessage}
                                    </div>
                                  </div>}
                 {!this.props.me.isHelpful() && this.props.me.isNative() && !this.props.isKeyboardActive && <div key='activate' className='keyboardHomeKeyboardActivate'>
                                                    <div className='keyboardHomeWordPacksLowIcon'>
                                                      <ReactSVG src={AISaid}/>
                                                    </div>
                                                    <img src={HomeNewUser}/>
                                                    <div className='keyboardHomeKeyboardActivateBlurb' style={blurbStyle}>
                                                      Select IntelliKey by tapping the Globe icon on your keyboard.
                                                      </div>
                                       </div>}
                 {this.props.me.isAskAppOnly() ?
                  [<HomeButton icon={AISaid} label={'steering chat models'} buttonLabel='Chat Models' action={this.openModels}/>,
                   <HomeButton icon={AISaid} label={'steering base models'} buttonLabel='Base Models' action={this.openBaseModels}/>]
                  : <HomeButton icon={AISaid} label={askLabel} buttonLabel={askButtonLabel} action={this.openChat}/>
                     }
                 {!this.props.me.isAskAppOnly() && !this.props.me.isBaseModel() && !this.props.me.isHelpful() && 
                   <HomeButton icon={Create} label={'expert writing/editing assistance'} buttonLabel='Write' action={this.openWritingAssistant}/>
                 }
                 <HomeButton className={'wordPackBuyButton' + (
                               isLowMessage ? ' wordPackBuyButtonLow' : '')}  icon={Buy} label={'add words to your account'} buttonLabel='Word Packs' action={this.buy}/>
                 {this.props.me && <HomeButton icon={Profile} label={'view your account'} buttonLabel='Account' action={this.account}/>}
               </div>
               {!this.props.me.isAskAppOnly() && !this.props.me.isBaseModel() && !this.props.me.isHelpful() &&
               <div className='keyboardHomeSavedButtons'>
                 <div className='homeButtonLabel keyboardHomeSavedButtonsLabel'>Saved</div>
                 {this.renderSavedButtonTabs()}
               </div>}
             </div>
           </div>
  }

  renderSavedButtonTabs() {
    const buttons = this.state.savedButtonSelector === 'edit' ? this.state.buttons : this.state.writeButtons
    const tabs = [
      {
        icon: Pen,
        label: 'Writing',
        selector: 'create'
      },
      {
        icon: Edit,
        label: 'Editing',
        selector: 'edit'
      }
    ]
    const selection = this.state.savedButtonSelector
    const select = selector => {
      this.setState({
        savedButtonSelector: selector
      })
    }
    return <div className='savedButtonTabsContainer'>
             <SavedButtonTabs tabs={tabs} selection={selection} select={select}/>
             <div className='keyboardHomeSavedButtonsList'>
               {this.renderSavedButtons(buttons)}
             </div>
           </div>
  }

  renderSavedButtons(buttons) {
    const Cats = {}
    for (const button of buttons) {
      const { id, instruction, category, busy } = button
      if (!Cats[category]) {
        Cats[category] = [button]
      } else {
        Cats[category].push(button)
      }
    }
    const cats = Object.keys(Cats)
    cats.sort()
    return cats.map(cat => {
      const buttons = Cats[cat]
      return <SavedButtonCategory category={cat} me={this.props.me} buttons={buttons}/>
    })
  }

  selectPage = page => {
    if (page === 'ask') {
      this.openChat()
    } else {
      this.back()
    }
  }

  componentDidMount() {
    this.props.onCreate(this)
    this.psub = this.props.me.observeProfile(this.props.uid).subscribe(data => {
      const { uid, displayName, email, profileImage, phoneNumber, tokensPurchased } = data
      this.setState({
        profile: {
          uid, displayName, email, profileImage, phoneNumber
        },
        tokensPurchased: tokensPurchased || 0
      })
    })

    this.usub = this.props.me.observeUsage().subscribe(usage => {
      const { words } = usage
      this.setState({
        wordsUsed: words
      })
    })

    this.psub1 = this.props.me.observePurchases().subscribe(change => {
      const wordPack = change.wordPack
      if (change.type === 'removed') {
        delete this.purchases[wordPack.id]
      } else {
        this.purchases[wordPack.id] = wordPack
      }
      this.updateWordPacksLater()
    })
    
    this.buttonSub = this.props.me.observeMyButtons().subscribe(change => {
      const button = change.button
      if (change.type === 'removed') {
        delete this.buttons[button.id]
      } else {
        this.buttons[button.id] = button
      }
      this.updateButtonsLater()
    })

    this.writeButtonSub = this.props.me.observeMyWritingButtons().subscribe(change => {
      const button = change.button
      if (change.type === 'removed') {
        delete this.writeButtons[button.id]
      } else {
        this.writeButtons[button.id] = button
      }
      this.updateWritingButtonsLater()
    })

    this.statusSub = this.props.me.observeOpenAIStatus().subscribe(status => {
      this.setState({
        openAIStatus: status
      })
    })
    if (this.props.notEnoughTokens) {
      this.onNotEnoughTokens()
    }

    if (this.props.me.isAskAppOnly()) {
      this.openChat({isOpenAI: true })
    }
    if (this.props.me.isHelpful()) {
      this.openChat({})
    }
  }

  onNotEnoughTokens = () => {
    
  }
               
  buttons = {}

  updateButtonsLater = () => {
    clearTimeout(this.buttonUpdater)
    this.buttonUpdater = setTimeout(this.updateButtonsNow, 200)
  }

  updateButtonsNow = () => {
    this.setState({
      buttons: Object.values(this.buttons)
    })
  }

  writeButtons = {}
  
  updateWritingButtonsLater = () => {
    clearTimeout(this.writingButtonUpdater)
    this.writingbuttonUpdater = setTimeout(this.updateWritingButtonsNow, 200)
  }

  updateWritingButtonsNow = () => {
    this.setState({
      writeButtons: Object.values(this.writeButtons)
    })
  }

  purchases = {}

  updateWordPacksLater = () => {
    clearTimeout(this.wordPackUpdater)
    this.wordPackUpdater = setTimeout(this.updateWordPacksNow, 200)
  }

  updateWordPacksNow = () => {
    const wordPacks = Object.values(this.purchases)
    let purchased = 0
    let nonFree = false
    for (const purchase of wordPacks) {
      purchased += (purchase.wordCount)
      if (purchase.price > 0) {
        nonFree = true
      }
    }
    this.setState({
      wordPacks,
      wordsPurchased: purchased,
      nonFree
    })
  }
  
  signUp = async () => {
    const back = this.back
    this.setState({
      subpage: () => <KeyboardLogin me={this.props.me} safeArea={true} back={back}/>
    })
  }
  
  componentWillUnmount() {
    if (super.componentWillUnmount) super.componentWillUnmount()
    if (this.psub) this.psub.unsubscribe()
    if (this.usub) this.usub.unsubscribe()
    if (this.buttonSub) this.buttonSub.unsubscribe()
    if (this.writingButtonSub) this.writingButtonSub.unsubscribe()
    if (this.statusSub) this.statusSub.unsubscribe()
  }
}
